import { CategoryProduct, DEFAULT_CATEGORY, Product } from "models/Product";
import {
  DEFAULT_COUNTRY,
  DEFAULT_LOCATION,
  DEFAULT_MENU,
  setSession,
} from "store/sessionSlice";
import React, { useEffect, useState } from "react";
import { setAllItems, setCategories, setToppings } from "store/itemSlice";
import { useAppDispatch, useAppSelector } from "store/hook";
import { useLocation, useSearchParams } from "react-router-dom";

import CloseIcon from "assets/icons/close";
import { CustomizationTabbarItem } from "pages/ProductDetailPage/CustomizationTabbar";
import { Gap } from "components/gap/Gap";
import IconSearch from "assets/icons/icon-search";
import MenuProductItem from "components/menu/MenuProductItem";
import { MenuWithCategory } from "components/menu/MenuWithCategory";
import { MenuWithCategorySkeleton } from "components/menu/MenuWithCategorySkeleton";
import PageHeader from "components/PageHeader";
import SearchIcon from "assets/icons/search-icon";
import { indexAllItems } from "utils/StoreUtils";
import { searchVietnamese } from "utils/OrderUtils";
import { useGetMenuQuery } from "store/api/menuApi";
import { useLanguage } from "hooks/useLanguage";

const MenuPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { isSearch = false } = state || {};
  const dispatch = useAppDispatch();
  const storedCategories = useAppSelector((root) => root.allItems.categories);
  const [activeCategories, setActiveCategories] = useState(storedCategories);
  const [tabbar, setTabbar] = useState<CategoryProduct[]>([]);

  const isBottomSheetOpen = useAppSelector((root) => root.bottomSheet.open);

  const { language, t } = useLanguage();

  useEffect(() => {
    const country = searchParams.get("c") || DEFAULT_COUNTRY;
    const location = searchParams.get("l") || DEFAULT_LOCATION;
    const table = searchParams.get("t") || undefined;
    dispatch(
      setSession({
        country,
        location,
        table,
      }),
    );
  }, []);

  const [allToppings, setAllToppings] = useState<CategoryProduct>();

  const [selectedCategory, setSelectedCategory] =
    useState<CategoryProduct>(DEFAULT_CATEGORY);

  const {
    data: categories = [],
    error,
    isLoading,
    isSuccess,
  } = useGetMenuQuery({
    country: DEFAULT_COUNTRY,
    location: DEFAULT_LOCATION,
    version: DEFAULT_MENU,
  });

  useEffect(() => {
    if (isSuccess && categories) {
      dispatch(setCategories(categories));
      dispatch(setAllItems(indexAllItems(categories as CategoryProduct[])));
      dispatch(setToppings(categories[categories.length - 1]));
      // update active categories to re-render
      setActiveCategories(categories as CategoryProduct[]);
    }
  }, [isSuccess, categories]);

  useEffect(() => {
    const TOPPING_INDEX = categories?.length - 1;
    const toppings = categories?.[TOPPING_INDEX];
    setAllToppings(toppings);
    setSelectedCategory(categories[0]);
    if (categories.length > 0) {
      const totalTab: CategoryProduct = {
        id: "total",
        type: "total",
        name: {
          en: "Total",
          vn: "Tất cả",
        },
        items: categories?.reduce?.((acc, category) => {
          if (category.type === "topping") return acc;
          return [...acc, ...category.items];
        }, []),
      };
      const exceptTopping = categories.filter(
        (item) => item.type !== "topping",
      );
      setTabbar([totalTab, ...exceptTopping]);
    }
  }, [categories]);

  const [active, setActive] = useState(0);

  const [showSearchBar, setSetShowSearchBar] = useState(isSearch);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<Product[]>([]);

  useEffect(() => {
    setSearchResults(
      searchVietnamese(
        searchQuery,
        tabbar?.[0]?.items.filter((item) => item?.public) || [],
      ),
    );
  }, [searchQuery]);

  return (
    <div className="pb-[100px]">
      <PageHeader
        title={"Menu"}
        statusBarColor="#fff"
        showBackButton={false}
        IconRight={showSearchBar ? undefined : SearchIcon}
        onIconRightClick={() => {
          setSetShowSearchBar(true);
        }}
      />

      {showSearchBar && (
        <div className="duration-400 relative flex flex-row items-center gap-2 bg-white px-4 pb-2 transition-all ease-in-out">
          <input
            autoFocus={true}
            type="text"
            style={{
              WebkitTextSizeAdjust: "140%",
            }}
            value={searchQuery}
            className="flex h-[36px] w-full resize-none flex-row rounded-[40px] bg-neutrals-divider px-4 pl-8 text-[12px] font-normal text-brand-4 placeholder:text-tertiary focus:border-[1px] focus:border-solid focus:border-brand-1 focus:outline-none"
            placeholder={t("search")}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="absolute left-6">
            <IconSearch />
          </div>
          <span
            onClick={() => {
              setSetShowSearchBar(false);
              setSearchQuery("");
            }}
            className="font-inter text-14px font-semibold text-brand-1"
          >
            {t("cancel")}
          </span>
        </div>
      )}

      <Gap size="S" />
      {searchQuery.length > 0 ? (
        <div>
          {searchResults?.length > 0 ? (
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
              {searchResults?.map((product, index) => (
                <MenuProductItem
                  key={`${index}-product-item`}
                  product={product}
                  index={index}
                />
              ))}
            </div>
          ) : (
            <p className="text-center text-14px text-gray-menu">
              {t("noResult")}
            </p>
          )}
        </div>
      ) : (
        <div>
          <div
            className={`sticky top-0 ${isBottomSheetOpen ? "z-0" : "z-10"} flex w-full flex-row gap-2 overflow-scroll px-4 py-2 backdrop-blur-sm scrollbar-hide`}
          >
            {tabbar.map((tab, index) => (
              <div
                key={tab.id}
                onClick={() => setActive(index)}
                className={`line-clamp-1 flex h-10 min-w-28 cursor-pointer items-center justify-center rounded-[40px]  border-solid border-gray-c5 px-2 text-14px font-normal
               ${index === active ? "border-[1px] bg-white text-brand-4" : "bg-white text-gray-menu "}`}
              >
                <p className="line-clamp-1"> {tab?.name?.[language]}</p>
              </div>
            ))}
          </div>
          <div className="grid w-full grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
            {tabbar?.[active]?.items
              ?.filter((product) => product?.public === true)
              .map((product, index) => (
                <MenuProductItem
                  key={`${index}-product-item`}
                  product={product}
                  index={index}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPage;
