import React from "react";
import { Gap } from "../../components/gap/Gap";
import { useLanguage } from "hooks/useLanguage";
import PageHeader from "components/PageHeader";
import Markdown from "react-markdown";
import { s3ImageUrl } from "utils/FileUtils";
import { Helmet } from "react-helmet";

const markdown = `

## Giới thiệu chung

Khi khách hàng truy cập vào website san.cafecủa Sắn Cafe, điều đó đồng nghĩa với việc khách hàng đã đồng ý với các điều khoản này. Sắn Cafe có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Điều khoản sử dụng vào bất kỳ lúc nào. Các thay đổi có hiệu lực ngay khi được đăng tải trên website hoặc ứng dụng mà không cần thông báo trước. Khi khách hàng tiếp tục sử dụng dịch vụ sau khi các thay đổi này được áp dụng, điều đó có nghĩa là khách hàng đã chấp nhận những thay đổi đó.

Khách hàng vui lòng thường xuyên kiểm tra các quy định và điều khoản dưới đây để cập nhật thông tin mới nhất.

---

## Chính sách Tài khoản Người dùng

Khách hàng cần cung cấp thông tin chính xác nhằm mục đích nhận được dịch vụ tốt nhất từ Sắn Cafe.

- Mỗi số điện thoại chỉ tạo được một (01) tài khoản cho mục đích sử dụng cá nhân.
- Để đảm bảo quyền lợi, khách hàng không chia sẻ thông tin tài khoản (tên đăng nhập và mật khẩu) hoặc chuyển nhượng tài khoản cho người khác mà chưa có sự đồng ý từ Sắn Cafe.
- Sắn Cafe có quyền khóa tài khoản hoặc hạn chế một số tính năng của tài khoản (ví dụ: Đặt hàng, Tích điểm) nếu phát hiện khách hàng vi phạm chính sách, bao gồm nhưng không giới hạn:
  - Tạo đơn hàng ảo hoặc đánh giá ảo.
  - Không nhận đơn hàng mà không có lý do chính đáng.
  - Có dấu hiệu lạm dụng ưu đãi để trục lợi.

Nếu tài khoản bị khóa, khách hàng cần hoàn thành quy trình xác minh để khôi phục.

---

## Giới thiệu về các loại dịch vụ

1. **Dịch vụ Giao hàng**: Sản phẩm sẽ được giao đến địa chỉ mà khách hàng lựa chọn sau khi đặt hàng thành công.
2. **Dịch vụ Mang đi**: Khách hàng đặt món trước và đến nhận tại cửa hàng đã chọn.

---

## Chính sách sử dụng dịch vụ

- Khách hàng được quyền chọn loại dịch vụ khi đặt hàng.
- Dịch vụ "Giao hàng" và "Mang đi" không áp dụng cho việc sử dụng tại chỗ.
- Sắn Cafe có quyền từ chối hỗ trợ giao hàng đối với đơn "Mang đi".

---

## Chấp nhận đơn hàng và giá cả

- Sau khi đơn hàng được xác nhận, Sắn Cafe sẽ xử lý và thực hiện giao hàng. 
- Sắn Cafe có quyền từ chối hoặc hủy đơn hàng trong các trường hợp như lỗi hệ thống, thông tin không đầy đủ, hoặc không thể liên hệ khách hàng sau nhiều lần.

---

## Thay đổi thông tin đặt hàng

- Khách hàng chỉ có thể thay đổi thông tin trước khi đơn hàng được thực hiện.
- Một số thay đổi sau thời điểm này sẽ phụ thuộc vào khả năng hỗ trợ của cửa hàng và đơn vị vận chuyển.

---

## Hủy bỏ Đặt hàng

- Khách hàng được quyền hủy đơn hàng trên ứng dụng nếu tài xế chưa nhận đơn hoặc cửa hàng chưa thực hiện đơn hàng.
- Nếu khách hàng không thể tự hủy, vui lòng liên hệ hotline để được hỗ trợ.

---

## Phương thức thanh toán

- **Thanh toán tiền mặt (COD)**: Khách hàng thanh toán khi nhận hàng.
- **Thanh toán trực tuyến**: Bao gồm thẻ nội địa, thẻ quốc tế, và các ví điện tử như MoMo, ZaloPay, ShopeePay.

---

## Chính sách hoàn tiền

- Trường hợp đơn hàng không được thực hiện, Sắn Cafe sẽ tiến hành hoàn tiền qua thẻ hoặc ví điện tử theo thời gian quy định:
  - Thẻ nội địa: Tối đa 7 ngày làm việc.
  - Thẻ quốc tế: Từ 30 đến 45 ngày làm việc.
  - Ví điện tử: Tối đa 7 ngày làm việc.

---

## Chính sách hoàn trả hàng

- Khách hàng kiểm tra sản phẩm tại thời điểm nhận hàng. 
- Nếu sản phẩm sai hoặc hư hại, khách hàng có quyền từ chối nhận và liên hệ hotline để được hỗ trợ.
- Sắn Cafe không hỗ trợ hoàn trả với các sản phẩm đã được chấp nhận và sử dụng bởi khách hàng.

---
`;

const PolicyPage: React.FC = () => {
  const { t } = useLanguage();
  return (
    <div
      className={`flex h-full w-full flex-col items-center pb-20 sm:h-screen`}
    >
      <Helmet>
        <meta name="theme-color" content={"#fef7e5"} />
      </Helmet>
      <div
        style={{ height: "calc(100% - 64px)" }}
        className="relative h-full w-full max-w-screen-md overflow-visible"
      >
        <img
          src={s3ImageUrl("bg_san_no_logo.webp")}
          alt=""
          className={`z-0 h-full w-full`}
        />
        <div className="absolute bottom-0 left-0 right-0 top-0 z-0 min-h-[320%] sm:min-h-[70%]">
          <PageHeader transparent title={t("termsAndConditions")} />
          <div
            style={{ height: "calc(100% - 64px)" }}
            className="mx-4 mt-20 flex h-full flex-col items-center rounded-t-2xl bg-white p-4 md:mx-8"
          >
            <Gap size={"S"} />
            <img
              src={s3ImageUrl("san-logo.png")}
              alt=""
              className="h-12 w-12"
            />
            <Markdown
              components={{
                h1(props) {
                  const { node, ...rest } = props;
                  return (
                    <h1 className={`text-3xl my-3 text-brand-4`} {...rest} />
                  );
                },
                h2(props) {
                  const { node, ...rest } = props;
                  return (
                    <h2 className={`my-1 text-18px text-brand-4`} {...rest} />
                  );
                },
                h3(props) {
                  const { node, ...rest } = props;
                  return (
                    <h3 className={`text-lg my-2 text-brand-4`} {...rest} />
                  );
                },
                ul(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                p(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                a(props) {
                  const { node, ...rest } = props;
                  return props.href?.includes("@") ? (
                    <a
                      href={`mailto:${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  ) : (
                    <a
                      href={`${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  );
                },
              }}
              className={`mt-6 text-brand-4 xl:mt-8`}
            >
              {markdown}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPage;
