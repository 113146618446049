import TooltipIcon from "assets/icons/icon-tooltip";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

export default function ShakingTooltip() {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      rotate: [0, -5, 5, 0],
      transition: {
        duration: 4,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "mirror",
      },
    });
  }, [controls]);

  return (
    <motion.div
      animate={controls}
      className="absolute -right-[10px] -top-[56px]"
      style={{ transformOrigin: "center bottom" }}
    >
      <TooltipIcon />
      <div className="absolute left-[60%] top-6 w-[70px] -translate-x-1/2 -translate-y-1/2">
        <p className="rotate-[6deg] font-inter text-[14px] font-semibold text-brand-4">
          Giải mã
        </p>
      </div>
    </motion.div>
  );
}
