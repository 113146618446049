import { Skeleton } from "antd";

export function OrderHistoryItemSkeleton() {
  return (
    <div className="flex h-full w-full flex-col px-1">
      {Array(6)
        .fill(null)
        .map((_, i) => (
          <div key={`skeleton-${i}`} className="flex w-full flex-col">
            <div className="flex flex-row items-start justify-between">
              <div className="flex flex-row items-start">
                <Skeleton.Node
                  active
                  style={{
                    height: 64,
                    width: 64,
                    marginTop: 8,
                    borderRadius: 8,
                  }}
                />
                <div className="ml-4 mt-4 flex flex-col justify-between">
                  <Skeleton.Button active style={{ height: 12, width: 120 }} />
                  <Skeleton.Button
                    active
                    style={{ height: 12, width: 80 }}
                    size="small"
                  />
                </div>
              </div>

              <div className="ml-4 mt-4 flex flex-col items-end justify-between">
                <Skeleton.Button active style={{ height: 12, width: 24 }} />
                <Skeleton.Button
                  active
                  style={{ height: 12, width: 4 }}
                  size="small"
                />
              </div>
            </div>
            <div className="flex w-full flex-row justify-end">
              <Skeleton.Button active style={{ height: 12, width: 200 }} />
            </div>
          </div>
        ))}
    </div>
  );
}
