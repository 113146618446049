import { CategoryProduct, DEFAULT_CATEGORY } from "../../models/Product";
import {
  DEFAULT_COUNTRY,
  DEFAULT_LOCATION,
  DEFAULT_MENU,
  setSession,
  setSessionId,
  updateTable,
} from "../../store/sessionSlice";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty, isNumber } from "lodash";
import { setAllItems, setCategories, setToppings } from "../../store/itemSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import BannerSection from "../../components/home-components/BannerSection";
import { CartProvider } from "contexts/cart/cart.provider";
import { DrawerProvider } from "contexts/drawer/drawer.provider";
import { Gap } from "../../components/gap/Gap";
import HomeUtilities from "components/home-components/HomeUtilities";
import { LOCAL_STORAGE_KEYS } from "helpers/constants";
import Layout from "../../containers/layout/layout";
import LoginToUnlockSection from "../../components/home-components/LoginToUnlockSection";
import { MenuWithCategory } from "../../components/menu/MenuWithCategory";
import { MenuWithCategorySkeleton } from "../../components/menu/MenuWithCategorySkeleton";
import { indexAllItems } from "../../utils/StoreUtils";
import { loginAsGuest } from "utils/AuthUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useGetMenuQuery } from "../../store/api/menuApi";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const MAX_TABLE = 20;

const HomePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const storedCategories = useAppSelector((root) => root.allItems.categories);
  const [activeCategories, setActiveCategories] = useState(storedCategories);
  const { isAuthenticated: isLogin } = useAuthContext();

  const sessionId = useAppSelector((root) => root.session.session_id);
  const { language } = useLanguage();

  useEffect(() => {
    const country = searchParams.get("c") || DEFAULT_COUNTRY;
    const location = searchParams.get("l") || DEFAULT_LOCATION;
    dispatch(
      setSession({
        country,
        location,
      }),
    );
  }, []);

  useEffect(() => {
    const guestToken = localStorage.getItem("guestToken");
    if (!isLogin && !guestToken) {
      loginAsGuest();
    }
  }, [isLogin]);

  useEffect(() => {
    const table = searchParams.get("tbl") || "";
    if (isNumber(+table) && +table > 0 && +table <= MAX_TABLE) {
      const tableInString = `${table}`.padStart(2, "0");
      localStorage.setItem(LOCAL_STORAGE_KEYS.TABLE_ID, tableInString);
    }
  }, []);

  useEffect(() => {
    if (!sessionId) {
      dispatch(setSessionId(uuidv4()));
    }
  }, []);

  const [allToppings, setAllToppings] = useState<CategoryProduct>();

  const { categories = [], isLoading } = useMenuData();

  // Remove last categories (toppings)
  const selectableProductsCategories = activeCategories?.filter(
    (category) => category.type !== "topping",
  );

  useEffect(() => {
    if (!isEmpty(categories)) {
      const TOPPING_INDEX = categories?.length - 1;
      const toppings = categories?.[TOPPING_INDEX];
      setAllToppings(toppings);
      setActiveCategories(categories);
    }
  }, [categories]);

  return (
    <DrawerProvider allToppings={allToppings}>
      <CartProvider>
        <Layout>
          <Gap size={"M"} />
          <BannerSection />
          <Gap size={"XS"} />
          <div className="px-4">
            {isLogin ? <HomeUtilities /> : <LoginToUnlockSection />}
          </div>
          <Gap size={"M"} />
          <div className={`flex w-full flex-col gap-6`}>
            {isLoading
              ? Array(3)
                  .fill(null)
                  .map((item, index) => (
                    <MenuWithCategorySkeleton key={`${index}-menu-item`} />
                  ))
              : selectableProductsCategories.map((category, index) => (
                  <MenuWithCategory
                    key={`${index}-${category.name}`}
                    categoryName={category.name?.[language]}
                    products={category.items}
                  />
                ))}
          </div>
          <Gap size={"L"} />
        </Layout>
      </CartProvider>
    </DrawerProvider>
  );
};

export default HomePage;
