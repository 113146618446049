import React, { useEffect, useRef } from "react";
import useVnpayCallbackParams from "../../hooks/useVnpayCallbackParams";
import {
  PAYMENT_METHOD,
  VnpayTransactionStatus,
} from "../../helpers/constants";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { clearOrder } from "../../store/orderSlice";
import { isEmpty } from "lodash";
import { useValidatePaymentMutation } from "../../store/api/consumerApi";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useLanguage } from "../../hooks/useLanguage";
import { useAuthContext } from "contexts/AuthContext";
import { setSessionId } from "store/sessionSlice";
import { v4 as uuidv4 } from "uuid";

const PaymentResultPage: React.FC = () => {
  const location = useLocation();
  const { payment_method = PAYMENT_METHOD.VNPAY } = location?.state || {};
  const { t } = useLanguage();
  const vnpayCallbackParams = useVnpayCallbackParams();
  const { isAuthenticated: isLogin } = useAuthContext();

  const bill = useAppSelector((state) => state.bill.bill);

  const isPaymentSuccess =
    payment_method === PAYMENT_METHOD.CASH ||
    vnpayCallbackParams.vnp_TransactionStatus ===
      VnpayTransactionStatus.Success;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Reset session id after payment to generate new hash from new session id and order items
    const newSessionId = uuidv4();
    dispatch(setSessionId(newSessionId));
  }, []);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <Helmet>
        <meta name="theme-color" content={"#fff"} />
      </Helmet>

      <div className="flex h-screen w-full max-w-screen-sm flex-col items-center justify-center p-4 xl:pt-20">
        <p
          className={`whitespace-pre-line text-center text-24px font-semibold text-brand-4`}
        >
          {isPaymentSuccess ? t("orderSuccessTitle") : t("orderFailedTitle")}
        </p>
        {!isPaymentSuccess && (
          <p
            className={`mt-2 whitespace-pre-line text-center text-14px font-semibold text-neutrals-grey`}
          >
            {t("orderFailedSubtitle")}
          </p>
        )}
        <img
          src={s3ImageUrl(
            isPaymentSuccess
              ? "san_order_success.webp"
              : "san_order_failed.png",
          )}
          alt=""
        />
        <button
          className={`mt-4 w-full rounded-[40px] border-2 border-solid bg-brand-1 py-3`}
          onClick={() => {
            if (isPaymentSuccess) {
              dispatch(clearOrder());
              navigate("/home");
            } else {
              // re-order
            }
          }}
        >
          <p className={`text-14px font-semibold text-white`}>
            {isPaymentSuccess ? t("home") : t("reorder")}
          </p>
        </button>
        <button
          className={`mt-2 w-full rounded-[40px] border-2 border-solid bg-white py-3`}
          onClick={() => {
            if (isPaymentSuccess) {
              // check order and bill
              navigate("/order-detail", {
                state: { bill },
              });
              dispatch(clearOrder());
            } else {
              // navigate to Home
              navigate("/home");
            }
          }}
        >
          <p className={`text-14px font-semibold text-brand-4`}>
            {isPaymentSuccess ? t("checkBillDetail") : t("backToHome")}
          </p>
        </button>
        {!isLogin && (
          <div className="flex flex-col items-center">
            <p
              className={`mt-6 whitespace-pre-line text-center text-12px font-normal text-tertiary`}
            >
              {t("registerSuggestion")}
            </p>
            <button onClick={() => navigate("/login")}>
              <p
                className={`mt-2 text-center text-12px font-semibold text-brand-1`}
              >
                {t("registerAccount")}
              </p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentResultPage;
