import CloudAnimation from "components/home-components/CloudAnimation";
import { Helmet } from "react-helmet";
import HomeHeader from "./header";

const Layout = (props) => (
  <main
    className="relative h-full min-h-screen w-screen max-w-screen-md flex-grow overflow-x-hidden bg-brand-shape-4"
    style={{
      minHeight: "-webkit-fill-available",
      WebkitOverflowScrolling: "touch",
      ...props.style,
    }}
  >
    {/* Background Image */}

    <div className="absolute left-0 right-0 top-0 z-0">
      <Helmet>
        <meta name="theme-color" content={"#FCF6F0"} />
      </Helmet>
      <CloudAnimation />

      {/* Main Content */}
      <div
        style={
          {
            // backgroundImage: `url(${s3ImageUrl("san_home_bg.png")})`,
          }
        }
        className="relative flex h-full min-h-screen w-full flex-grow flex-col"
      >
        <div className="z-10 flex-auto md:px-[35px]">
          <div className="z-10">
            <HomeHeader />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  </main>
);

export default Layout;
