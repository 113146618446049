import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order, ProductItem } from "./type";
import { findIndex, isEqual, remove, sortBy, update } from "lodash";
import { FLAGS, PAYMENT_METHOD } from "helpers/constants";

interface OrderState {
  currentOrder: Order | null;
  paymentMethod: PAYMENT_METHOD;
}

const initialState: OrderState = {
  currentOrder: null,
  paymentMethod: FLAGS.ENABLE_VNPAY
    ? PAYMENT_METHOD.VNPAY
    : PAYMENT_METHOD.CASH,
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      state.currentOrder = action.payload;
    },
    addItem: (state, action: PayloadAction<ProductItem>) => {
      if (state.currentOrder) {
        const existingItem = state.currentOrder.items.find((item) => {
          return (
            item.item_id === action.payload.item_id &&
            item.customizable_hot === action.payload.customizable_hot &&
            item.customizable_ice === action.payload.customizable_ice &&
            item.customizable_sugar === action.payload.customizable_sugar &&
            isEqual(sortBy(item.children), sortBy(action.payload.children)) &&
            isEqual(item.size, action.payload.size)
          );
        });

        if (existingItem) {
          existingItem.quantity += action.payload.quantity;
        } else {
          state.currentOrder.items.push(action.payload);
        }
      }
    },
    updateItemQuantity: (
      state,
      action: PayloadAction<{ id: string; quantity: number }>,
    ) => {
      if (state.currentOrder) {
        const item = state.currentOrder.items.find(
          (item) => item.id === action.payload.id,
        );
        if (item) {
          item.quantity = action.payload.quantity;
        }
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.items = state.currentOrder.items.filter(
          (item) => item.id !== action.payload,
        );
      }
    },
    clearOrder: (state) => {
      state.currentOrder = null;
    },
    setExperience: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.experience_id = action.payload;
      }
    },
    removeExperience: (state) => {
      if (state.currentOrder) {
        state.currentOrder.experience_id = null;
      }
    },
    setDedupeId: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.dedup_id = action.payload;
      }
    },
    applyVoucher: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        const newVoucher = action.payload;
        const existingVoucher = state.currentOrder.discount_codes?.find(
          (code) => code === newVoucher,
        );

        if (existingVoucher) {
          // do nothing
        } else {
          state.currentOrder.discount_codes?.push(newVoucher);
        }
      }
    },
    updateItem: (state, action: PayloadAction<ProductItem>) => {
      if (state.currentOrder) {
        const newItem = action.payload;
        // remove the item if quantity is 0
        const filteredItems = state.currentOrder.items.filter(
          (item) => item.id !== newItem.id,
        );
        const existingItem = state.currentOrder.items.find((item) => {
          return (
            item.customizable_hot === action.payload.customizable_hot &&
            item.customizable_ice === action.payload.customizable_ice &&
            item.customizable_sugar === action.payload.customizable_sugar &&
            isEqual(sortBy(item.children), sortBy(action.payload.children)) &&
            isEqual(item.size, action.payload.size)
          );
        });

        const isCurrentItem = existingItem?.id === newItem.id;

        const newItems = state.currentOrder.items.map((item) => {
          if (item.id === newItem.id) {
            return newItem;
          }
          return item;
        });

        // update the item if quantity is greater than 0
        const updatedQuantityItems = state.currentOrder.items.map((item) => {
          if (item.id === newItem.id) {
            return {
              ...newItem,
              quantity: newItem.quantity + (existingItem?.quantity || 0),
            };
          }
          return item;
        });

        const mergedItems = updatedQuantityItems.filter((item) => {
          return item.id !== existingItem?.id;
        });

        state.currentOrder.items =
          newItem.quantity > 0
            ? isCurrentItem
              ? newItems
              : mergedItems
            : filteredItems;
      }
    },
    doNothing: () => {},
    addNote: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.note = action.payload;
      }
    },
    setPaymentMethod: (state, action: PayloadAction<PAYMENT_METHOD>) => {
      state.paymentMethod = action.payload;
    },
    addMetaData: (state, action: PayloadAction<Record<string, string>>) => {
      if (state.currentOrder) {
        state.currentOrder.meta = {
          ...state.currentOrder.meta,
          ...action.payload,
        };
      }
    },
  },
});

export const {
  setOrder,
  addItem,
  updateItemQuantity,
  removeItem,
  clearOrder,
  setExperience,
  setDedupeId,
  applyVoucher,
  updateItem,
  removeExperience,
  doNothing,
  addNote,
  setPaymentMethod,
  addMetaData,
} = orderSlice.actions;

export default orderSlice.reducer;
