import * as React from "react";

const TooltipIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={91}
    height={55}
    fill="none"
    {...props}
  >
    <defs>
      <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
        <feDropShadow dx="2" dy="2" stdDeviation="3" floodColor="rgba(0,0,0,0.3)" />
      </filter>
    </defs>

    <path
      fill="#fff"
      d="M3.051 17.894C4.128 8.012 13.011.872 22.894 1.95L72.6 7.362c9.883 1.076 17.022 9.96 15.946 19.843-1.076 9.882-9.96 17.021-19.843 15.945l-49.706-5.413C9.114 36.661 1.975 27.777 3.05 17.894Z"
      filter="url(#shadow)"
    />
    <path
      fill="#fff"
      d="m56.598 50.885-4.191-9.51 10.331 1.126-6.14 8.384Z"
      filter="url(#shadow)"
    />
  </svg>
);

export default TooltipIcon;