import VariantSelection from "components/bottom-sheet/VariantSelection";
import {
  CategoryProduct,
  Product,
  SelectedTopping,
  SizeType,
} from "models/Product";
import { ProductItemSource } from "pages/ProductDetailPage/ProductDetailPage";
import SizeTabbar from "pages/ProductDetailPage/SizeTabbar";
import SugarTabbar from "pages/ProductDetailPage/SugarTabbar";
import ToppingTabbar from "pages/ProductDetailPage/ToppingTabbar";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetMenuItemQuery, useLazyGetMenuQuery } from "store/api/menuApi";
import { useAppDispatch, useAppSelector } from "store/hook";
import {
  addItem,
  addMetaData,
  doNothing,
  removeItem,
  setOrder,
  updateItem,
} from "store/orderSlice";
import {
  DEFAULT_COUNTRY,
  DEFAULT_LOCATION,
  DEFAULT_MENU,
} from "store/sessionSlice";
import { ProductItem } from "store/type";
import { calculateItemTotalPrice, formatCurrency } from "utils/ConcurencyUtils";
import { scrollContent } from "utils/Helper";
import { createOrderDefault } from "utils/OrderUtils";
import { v4 as uuidv4 } from "uuid";
import { useLanguage } from "./useLanguage";
import { Skeleton } from "antd";
import { Gap } from "components/gap/Gap";
import DrinkIllustrator from "components/DrinkIllustrator";
import CustomizationTabbar from "pages/ProductDetailPage/CustomizationTabbar";
import IconMinus from "assets/icons/icon-minus";
import IconPlus from "assets/icons/icon-plus";
import SanButton from "components/SanButton";
import { isEmpty, set } from "lodash";
import { WithSnackbarProps } from "hoc/withSnackbar";
import { LOCAL_STORAGE_KEYS } from "helpers/constants";
import { useAuthContext } from "contexts/AuthContext";

export type UseCustomization = {
  productId?: string;
  customizationState?: {
    item: ProductItem;
    tarotCardId?: string;
    activeIndex?: number;
    from: ProductItemSource;
  };
  onConfirmClicked?: () => void;
};

const INIT_ICE_PERCENT = 100;
const INIT_SUGAR_PERCENT = 100;

const NOT_SHOW_CUSTOMIZATION = ["topping", "bakery"];

export const useCustomization = (
  props: UseCustomization & WithSnackbarProps,
) => {
  const {
    productId,
    customizationState,
    onConfirmClicked,
    showSnackbar = () => null,
  } = props;
  const dispatch = useAppDispatch();
  const { product_id } = useParams();
  const osRef = useRef<any>();
  const [activeTab, setActiveTab] = useState(0);
  const sessionId = useAppSelector((root) => root.session.session_id);
  const tableId = localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_ID);

  const { isAuthenticated: isLogin } = useAuthContext();

  const { state, key } = useLocation();
  const {
    item: navigatedItem = {},
    from = ProductItemSource.NONE,
    tarotCardId,
    activeIndex,
  } = customizationState || state || {};

  const { data: product = {}, isLoading } = useGetMenuItemQuery({
    country: DEFAULT_COUNTRY,
    location: DEFAULT_LOCATION,
    version: DEFAULT_MENU,
    item_id: product_id || productId,
  });

  useEffect(() => {
    setActiveTab(0);
    setSelectedToppings([]);
  }, [product?.id]);

  const updateItemPage = (productItem: ProductItem) => {
    setSelectedSize(productItem.size as SizeType);
    setIsHot(productItem.customizable_hot);
    setIcePercent(productItem.customizable_ice || INIT_ICE_PERCENT);
    setSugarPercent(productItem.customizable_sugar || INIT_SUGAR_PERCENT);
    setQuantity(productItem.quantity);
    setActiveTab(0);
    const navigatedItemToppings = navigatedItem?.children?.map((item) => {
      const fullInfoItem = allToppings?.items.find(
        (topping) => topping.id === item?.item_id,
      ) as Product;
      return {
        id: item?.item_id,
        name: item?.name,
        price:
          fullInfoItem?.pricing?.find((item) => item.size === "small")?.value ||
          0,
        size: "small",
      };
    });
    setSelectedToppings(navigatedItemToppings || []);
  };

  useEffect(() => {
    if (!isEmpty(navigatedItem)) updateItemPage(navigatedItem);
  }, [customizationState?.item]);

  const [getMenu] = useLazyGetMenuQuery();

  const favoritesInString = useAppSelector(
    (root) => root.allItems.favoritesInString,
  );

  const isFavoriteFromStore = favoritesInString.includes(product?.id || "");

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    setIsFavorite(isFavoriteFromStore);
  }, [isFavoriteFromStore]);
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const localToppings = useAppSelector((root) => root.allItems.toppings);

  const [allToppings, setAllToppings] = useState(localToppings);

  useEffect(() => {
    if (!currentOrder && sessionId) {
      dispatch(
        setOrder(
          createOrderDefault({
            session_id: sessionId,
            user_id: null,
            table_id: tableId || "",
          }),
        ),
      );
    }
  }, [currentOrder, sessionId]);

  const { language, t } = useLanguage();

  const [quantity, setQuantity] = useState(navigatedItem?.quantity || 1);

  const { id, name, pricing, customizable, description, type } = product || {};

  const { hot, sugar, ice, topping = [] } = customizable || {};

  const isHaveIce = hot === false;

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (navigatedItem?.size) setSelectedSize(navigatedItem?.size);
    else {
      if (pricing?.length === 1 || pricing?.length === 2)
        setSelectedSize(pricing[0].size);
      else if (pricing?.length === 3) setSelectedSize(pricing[1].size);
    }
  }, [pricing]);

  const [selectedSize, setSelectedSize] = useState<SizeType>(
    navigatedItem?.size || "small",
  );
  const selectedPrice = pricing?.find((item) => item.size === selectedSize);

  const [icePercent, setIcePercent] = useState(
    navigatedItem?.customizable_ice || INIT_ICE_PERCENT,
  );
  const [sugarPercent, setSugarPercent] = useState(
    navigatedItem?.customizable_sugar || INIT_SUGAR_PERCENT,
  );
  const [selectedToppings, setSelectedToppings] = useState<SelectedTopping[]>(
    [],
  );

  useEffect(() => {
    // load allTopping when share link product detail => allTopping will be not available
    if (!localToppings) {
      getMenu({
        country: DEFAULT_COUNTRY,
        location: DEFAULT_LOCATION,
        version: DEFAULT_MENU,
      }).then((res) => {
        const categories: CategoryProduct[] = res.data || [];
        setAllToppings(
          categories?.find((item) => item.type === "topping") || null,
        );
      });
    }
  }, [localToppings]);

  useEffect(() => {
    const navigatedItemToppings = navigatedItem?.children?.map((item) => {
      const fullInfoItem = allToppings?.items.find(
        (topping) => topping.id === item?.item_id,
      ) as Product;
      return {
        id: item?.item_id,
        name: item?.name,
        price:
          fullInfoItem?.pricing?.find((item) => item.size === "small")?.value ||
          0,
        member_price:
          fullInfoItem?.pricing?.find((item) => item.size === "small")
            ?.member_value || 0,
        size: "small",
      };
    });
    setSelectedToppings(navigatedItemToppings || []);
  }, [allToppings]);

  const [isHot, setIsHot] = useState(
    navigatedItem?.customizable_hot || !isHaveIce,
  );

  useEffect(() => {
    setIsHot(!isHaveIce);
  }, [isHaveIce]);

  useEffect(() => {
    setTotalPrice(
      calculateItemTotalPrice(
        product as Product,
        selectedSize,
        selectedToppings,
        isLogin,
      ),
    );
  }, [selectedToppings, selectedSize, product, topping]);

  useEffect(() => {
    if (quantity === 0) {
      dispatch(removeItem(id));
    }
  }, [quantity]);

  const buttonActionMapping = {
    [ProductItemSource.ORDER]: updateItem,
    [ProductItemSource.MENU]: addItem,
    [ProductItemSource.NONE]: quantity > 0 ? addItem : doNothing,
  };

  const snackBarContentMapping = (itemName: string) => {
    return {
      [ProductItemSource.ORDER]: t("updateItemInCart"),
      [ProductItemSource.MENU]: t("addItemToCart").replace("%s", itemName),
      [ProductItemSource.NONE]: t("addItemToCart").replace("%s", itemName),
    };
  };

  const onClickConfirm = () => {
    const { id, image, name, description } = product || {};

    const price = pricing?.find((item) => item.size === selectedSize);
    const { value = 0, member_value = 0 } = price || {};

    const finalPrice = isLogin ? member_value : value;

    const toppingTotalPrice =
      selectedToppings?.reduce<number>((result, toppingItem) => {
        const { price = 0, member_price = 0 } = toppingItem || {};
        const finalToppingPrice = isLogin ? member_price : price;
        return result + finalToppingPrice;
      }, 0) || 0;

    const willAddToppings = selectedToppings.map((toppingItem) => ({
      item_id: toppingItem.id,
      size: toppingItem.size,
      name: toppingItem.name,
      quantity: 1,
      customizable_hot: isHot,
      customizable_ice: icePercent,
      customizable_sugar: sugarPercent,
      children: [],
    }));

    const itemWillUpdate = {
      id: navigatedItem?.id || `${id}-${Date.now()}`,
      item_id: id,
      size: selectedSize,
      quantity: quantity,
      customizable_hot: isHot,
      customizable_ice: isHot ? null : icePercent,
      customizable_sugar: sugarPercent,
      children: willAddToppings,
      name: name?.[language as string],
      discount_codes: [],
      total_price: finalPrice + toppingTotalPrice || 0,
      price: finalPrice,
      image: image,
      description: description?.[language as string],
    };

    const action = buttonActionMapping?.[from] || buttonActionMapping.NONE;

    const metaData = {
      [`tarot_${activeIndex}`]: tarotCardId,
    };
    if (tarotCardId) {
      dispatch(addMetaData(metaData));
    }

    dispatch(action(itemWillUpdate as ProductItem));
    dispatch(addMetaData({ item_name: name?.[language as string] }));
    onConfirmClicked?.();

    showSnackbar({
      message:
        snackBarContentMapping(name?.[language as string])?.[from] ||
        snackBarContentMapping(name?.[language as string]).NONE,
    });
  };

  const onChangeSize = (value: SizeType) => {
    setSelectedSize(value);
  };

  const onChangeSugar = (percent: number) => {
    setSugarPercent(percent);
  };

  const buttonTitleMapping = {
    [ProductItemSource.ORDER]: `${quantity > 0 ? `${t("update")} (${formatCurrency(totalPrice * quantity)})` : t("delete")}`,
    [ProductItemSource.MENU]: t("add"),
    [ProductItemSource.NONE]: `${quantity > 0 ? `${t("add")} (${formatCurrency(totalPrice * quantity)})` : t("goBack")}`,
  };

  const componentByTab = {
    0: (
      <VariantSelection
        sugar={sugarPercent}
        onChangeSugar={onChangeSugar}
        icePercent={icePercent}
        setIcePercent={setIcePercent}
        isHot={isHot}
        hot={hot}
        setIsHot={setIsHot}
        scrollToIceSelector={() => {
          scrollContent(osRef, "iceGapID");
        }}
      />
    ),
    1: (
      <ToppingTabbar
        selectedToppings={selectedToppings}
        setSelectedToppings={setSelectedToppings}
        toppings={allToppings?.items.filter((item) => {
          return topping?.includes(item.id);
        })}
      />
    ),
  };

  const CustomizationView = () => {
    return (
      <div className="flex h-fit w-full flex-col p-4">
        <div className="flex flex-row items-center justify-between">
          <div className="flex w-full flex-col">
            {!isLoading ? (
              <span className="m-0 text-[20px] font-semibold">
                {name?.[language]}
              </span>
            ) : (
              <Skeleton.Node active style={{ height: 20, width: "50vw" }} />
            )}
            {!isLoading ? (
              <div className="flex w-full flex-row items-center justify-start gap-2">
                <span className="text-[18px] font-bold text-brand-1">
                  {formatCurrency(
                    +`${isLogin ? selectedPrice?.member_value : selectedPrice?.value}`,
                  )}
                </span>
                {isLogin && (
                  <span className="mt-1 text-[12px] font-normal text-tertiary line-through">
                    {`${formatCurrency(+`${selectedPrice?.value}`)}`}
                  </span>
                )}
              </div>
            ) : (
              <Skeleton.Node active style={{ height: 16, width: "30vw" }} />
            )}
            <Gap size={"XS"} />
            {!isLoading ? (
              <span className="m-0 text-[12px] font-normal text-gray-menu">
                {description?.[language]}
              </span>
            ) : (
              <Skeleton.Node active style={{ height: 20, width: "80vw" }} />
            )}
          </div>

          {/* <DrinkIllustrator
            isLoading={isLoading}
            size={selectedSize}
            isHot={isHot}
            icePercent={icePercent}
          /> */}
        </div>
        {NOT_SHOW_CUSTOMIZATION.includes(type) || isLoading ? null : (
          <div className="flex w-full flex-col">
            <Gap size={"M"} />
            <div className="h-[1px] w-full bg-divide" />
            <Gap size={"S"} />
            <SizeTabbar
              className="h-fit pb-2"
              onChangeSize={onChangeSize}
              pricing={pricing}
              selectedSize={selectedSize}
            />
            {!isLoading &&
              topping?.length > 0 &&
              !NOT_SHOW_CUSTOMIZATION.includes(type) && (
                <CustomizationTabbar
                  shouldShowTopping={topping?.length > 0}
                  active={activeTab}
                  setActive={setActiveTab}
                />
              )}
            {componentByTab[activeTab]}
            <Gap size={"M1"} />
          </div>
        )}
        {NOT_SHOW_CUSTOMIZATION.includes(type) && <Gap size={"XL"} />}
      </div>
    );
  };

  const UpdateQuantityView = () => {
    return (
      <div className="fixed bottom-4 left-0 right-0 flex w-full flex-row items-center justify-center">
        <div className="flex w-full max-w-screen-md flex-row px-4">
          <div className={`flex flex-row items-center gap-1 px-2`}>
            <div
              className={`flex h-11 w-11 flex-row items-center justify-center rounded-full border-[1px] border-solid bg-white ${quantity !== 0 ? "border-brand-1" : "border-border-1"}`}
            >
              <IconMinus
                color={quantity === 0 ? "#B5B5B5" : "#9F2B2B"}
                onClick={() => {
                  setQuantity(quantity - 1);
                }}
              />
            </div>
            <input
              type="number"
              value={quantity}
              className={`h-11 w-[65px] flex-row items-center justify-center rounded-[40px] border-[1px] border-solid border-border-1 text-center text-14px text-brand-4 focus:border-brand-1 focus:outline-none focus:ring-0`}
              onChange={(event) => {
                +event.target.value > 0 && setQuantity(+event.target.value);
              }}
            />
            <div className="flex h-11 w-11 flex-row items-center justify-center rounded-full border-[1px] border-solid border-brand-1 bg-white">
              <IconPlus
                color={"#9F2B2B"}
                onClick={() => {
                  setQuantity(quantity + 1);
                }}
              />
            </div>
          </div>
          <SanButton
            disabled={isLoading}
            title={
              buttonTitleMapping?.[from] ||
              buttonTitleMapping?.[ProductItemSource.NONE]
            }
            onClick={onClickConfirm}
          />
        </div>
      </div>
    );
  };

  return {
    CustomizationView,
    UpdateQuantityView,
    key,
    isFavorite,
    isLoading,
    product,
    osRef,
    setIsFavorite,
  };
};
