import { Product } from "models/Product";
import { BillItem, Order, ProductItem } from "../store/type";
import { sha256 } from "js-sha256";

const DEFAULT_MENU_ID = "vn.han_1nx.v1";
const DEFAULT_ZONE = "han";
export const createOrderDefault = ({
  session_id,
  user_id = null,
  user_name = "",
  menu_id = DEFAULT_MENU_ID,
  table_id = "",
}) => {
  return {
    zone: DEFAULT_ZONE,
    menu_id,
    session_id,
    type: "in_store",
    user_id,
    user_name,
    note: "",
    items: [],
    discount_codes: [],
    experience_id: null,
    dedup_id: "",
    table_id,
  } as Order;
};

// QR code => vn, han, street, table => menu

const sizeTitleMapping = {
  small: "Nhỏ",
  medium: "Vừa",
  large: "Lớn",
};

export const generateCustomizeDescription = (
  item: ProductItem | BillItem,
  isOneSize?: boolean,
  t?: any,
) => {
  const { customizable_hot, size, customizable_ice, customizable_sugar } = item;
  const descriptions: string[] = [];
  if (size) {
    if (isOneSize) {
      descriptions.push(t?.("oneSize"));
    } else {
      descriptions.push(sizeTitleMapping[size]);
    }
  }
  if (customizable_hot) descriptions.push(t?.("hot").toLowerCase());
  if (customizable_ice)
    descriptions.push(`${customizable_ice}% ${t?.("ice").toLowerCase()}`);
  if (customizable_sugar)
    descriptions.push(`${customizable_sugar}% ${t?.("sugar").toLowerCase()}`);

  return descriptions.join(", ");
};

export const normalizeVietnamese = (text: string): string => {
  return text
    .normalize("NFD") // Decompose characters with diacritics
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
    .replace(/đ/g, "d") // Convert `đ` to `d`
    .replace(/Đ/g, "D") // Convert `Đ` to `D`
    .toLowerCase(); // Convert to lowercase
};

export const searchVietnamese = (
  query: string,
  items: Product[],
): Product[] => {
  const normalizedQuery = normalizeVietnamese(query).toLowerCase();
  const queryTerms = normalizedQuery.split(/\s+/);
  return items?.filter?.((item) => {
    const normalizedItemInEnglish = normalizeVietnamese(
      item.name?.en,
    ).toLowerCase();
    const normalizedItemInVietnamese = normalizeVietnamese(
      item.name?.vn,
    ).toLowerCase();
    return queryTerms.every(
      (term) =>
        normalizedItemInEnglish.includes(term) ||
        normalizedItemInVietnamese.includes(term),
    );
  });
};

function normalizeItems(items: ProductItem[]): any {
  return items
    .map((item) => ({
      item_id: item.item_id,
      size: item.size,
      quantity: item.quantity,
      customizable_hot: item.customizable_hot,
      customizable_ice: item.customizable_ice,
      customizable_sugar: item.customizable_sugar,
      total_price: item.total_price,
      price: item.price,
      children: item.children ? normalizeItems(item.children) : [],
    }))
    .sort((a, b) => a.item_id.localeCompare(b.item_id));
}

export function generateDedupId(order: Order): string {
  const { session_id, items } = order;
  const normalizedItems = normalizeItems(items);

  const dataString = JSON.stringify({ session_id, items: normalizedItems });
  const hash = sha256(dataString);
  return [
    hash.substring(0, 8),
    hash.substring(8, 12),
    hash.substring(12, 16),
    hash.substring(16, 20),
    hash.substring(20, 32),
  ].join("-");
}
