import TableSection from "../../components/order/TableSection";
import { Gap } from "../../components/gap/Gap";
import PageHeader from "../../components/PageHeader";
import { useLanguage } from "../../hooks/useLanguage";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hook";
import MyBillSection from "components/order/MyBillSection";
import TotalPriceSectionInOrderDetail from "./TotalPriceSection";
import { BottomSheet } from "react-spring-bottom-sheet";
import CloseIcon from "assets/icons/close";
import SanButton from "components/SanButton";
import { useState } from "react";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

interface OrderDetailPageProps {}

export default function OrderDetailPage(props: OrderDetailPageProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { bill, order } = state || {};
  const { items, vnp_url = "", charges } = bill || {};
  const { total = 0, sub_total, discount } = charges || {};
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [value, setValue] = useState<number | null>(0);

  const labels: { [index: string]: string } = {
    0: t(""),
    1: t("veryBad"),
    2: t("bad"),
    3: t("normal"),
    4: t("good"),
    5: t("veryGood"),
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const orderStatusTitleByStatus = {
    paid: "Đã thanh toán",
    pending: "Đơn hàng đang được chuẩn bị",
    fulfilled: "Đã giao hàng",
  };

  return (
    <div
      className={`flex h-full min-h-screen flex-col items-center bg-default-1`}
    >
      <PageHeader title={t("orderDetailTitle")} />
      <div className="h-full w-full max-w-screen-md p-4">
        <div className={`flex flex-col items-center justify-center`}>
          <p className={`text-18px font-bold text-brand-4`}>
            {orderStatusTitleByStatus[order?.status] || t("orderInProgress")}
          </p>
          <Gap size={"M"} />
          <img
            src={s3ImageUrl("order_in_progress.png")}
            className={`w-3/4 sm:w-1/2`}
            alt=""
          />
          <Gap size={"M"} />
        </div>
        <TableSection />
        {/* <Gap size={"S"} /> */}
        {/* <TimeEstimationSection /> */}
        <Gap size={"S"} />
        <MyBillSection items={bill?.items} />
        <Gap size={"S"} />
        <TotalPriceSectionInOrderDetail
          total={total}
          subTotal={sub_total}
          discount={`${discount}`}
        />
        <Gap size={"S"} />
        <button
          className={`mt-4 w-full rounded-[40px] border-2 border-solid bg-brand-1 py-3`}
          onClick={() => {
            setOpen(true);
          }}
        >
          <p className={`text-14px font-semibold text-white`}>
            {t("reviewProduct")}
          </p>
        </button>
        <button
          className={`mt-2 w-full rounded-[40px] border-2 border-solid bg-white py-3`}
          onClick={() => {
            navigate("/bill-detail", {
              state: { bill },
            });
          }}
        >
          <p className={`text-14px font-semibold text-brand-4`}>
            {t("checkBill")}
          </p>
        </button>
        <Gap size={"M"} />
        <BottomSheet
          open={open}
          expandOnContentDrag
          onDismiss={() => {
            setOpen(false);
          }}
        >
          <div className={`flex h-fit w-full flex-col items-center px-2`}>
            <div
              className="absolute right-6 top-6"
              onClick={() => setOpen(false)}
            >
              <CloseIcon width="12px" height="12px" />
            </div>
            <div className="w-full max-w-screen-md">
              <p className={`text-center text-16px font-medium text-brand-4`}>
                {t("writeFeedback")}
              </p>
              <Gap size={"M"} />
              <p className={`text-center text-16px font-bold text-brand-4`}>
                {t("feedbackTitle")}
              </p>
              <p
                className={`text-center font-inter text-12px font-normal text-tertiary`}
              >
                {t("feedbackDescription")}
              </p>
              <Gap size={"M"} />
              <div className="flex w-full flex-col items-center">
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <Gap size={"XXS"} />
                <span>{value !== null && `${labels[value]}`}</span>
              </div>
              <Gap size={"M"} />
              <div className="flex w-full flex-row justify-between gap-1">
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="h-[105px] w-full rounded-[16px] bg-neutrals-divider p-4 text-14px font-normal text-brand-4 placeholder:text-tertiary focus:border-[1px] focus:border-solid focus:border-brand-1 focus:outline-none"
                  placeholder={t("inputFeedback")}
                />
              </div>
              <Gap size={"M"} />
              <SanButton
                onClick={() => {
                  setOpen(false);
                }}
                title={t("send")}
              />
              <Gap size={"M"} />
            </div>
          </div>
        </BottomSheet>
      </div>
    </div>
  );
}
