import "./tarotResult.css";

import { AiResponse, Card } from "store/type";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { useAppDispatch, useAppSelector } from "store/hook";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "assets/icons/close";
import { FLAGS } from "helpers/constants";
import { Gap } from "components/gap/Gap";
import IconPlus from "assets/icons/icon-plus";
import ImageWithModal from "components/ImageWithModal";
import Lottie from "lottie-react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import PageHeader from "components/PageHeader";
import { Product } from "models/Product";
import ProductDetailBottomSheet from "components/bottom-sheet/ProductDetailBottomSheet";
import { ProductItemSource } from "pages/ProductDetailPage/ProductDetailPage";
import { Skeleton } from "antd";
import { TarotCardWrapper } from "components/TarotCardWrapper";
import { formatCurrency } from "utils/ConcurencyUtils";
import { openProductDetail } from "store/bottomSheetSlice";
import { removeExpSessionId } from "store/sessionSlice";
import { s3ImageUrl } from "utils/FileUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";
import { useSwipeable } from "react-swipeable";

export type TarotResultProps = {};

export default function TarotResult(props: TarotResultProps) {
  const { t, language } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { tarotResult = {} } = state || {};
  const { message, cards, recommendations } =
    (tarotResult?.ai_response as AiResponse) || {};
  const { isAuthenticated: isLogin } = useAuthContext();
  const {} = useMenuData();
  const allItems = useAppSelector((root) => root.allItems.allItems);

  const [showSpecialDrinkModal, setShowSpecialDrinkModal] = useState(false);
  const [showSpecialDrinkAnimation, setShowSpecialDrinkAnimation] =
    useState(false);

  const recommendationsByIndex = {
    0: "present",
    1: "future",
    2: "advice",
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const containerRef = useRef<any>(null);
  const spanRefs = useRef<any>([]);

  useEffect(() => {
    const element = spanRefs.current[activeIndex];
    if (element && containerRef.current) {
      const container = containerRef.current;
      const offsetPosition = element.offsetTop - container.offsetTop - 50;
      container.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [activeIndex]);

  const selectedCardTitleByIndex = {
    0: t("present"),
    1: t("future"),
    2: t("advice"),
  };

  const { future, present, advice, final } = message;

  const tarotMeanings = [present?.[0], future?.[0], advice?.[0], final];

  const drinkMeanings = [present?.[1], future?.[1], advice?.[1]];

  const [swiper, setSwiper] = useState<any>(null);

  const outsideSlideTo = (index: number) => {
    if (swiper) {
      swiper?.slideTo?.(index);
    }
  };

  const showedAnimationRef = useRef(false);

  const activeDrink =
    allItems?.[recommendations?.[recommendationsByIndex[activeIndex]]];

  useEffect(() => {
    if (activeDrink && activeDrink?.public === false) {
      if (!showedAnimationRef.current) {
        setShowSpecialDrinkModal(true);
        showedAnimationRef.current = true;
      }
    }
  }, [activeIndex]);

  const isSpecialDrink =
    activeDrink &&
    !showSpecialDrinkModal &&
    showSpecialDrinkAnimation &&
    activeDrink?.public === false;

  const onClickDrink = () => {
    dispatch(
      openProductDetail({
        id: recommendations?.[recommendationsByIndex[activeIndex]],
        name: {
          en: "",
          vn: "",
        },
        tarotCardId: cards?.[activeIndex]?.card_id,
        activeIndex: activeIndex,
        from: ProductItemSource.NONE,
      } as Product & {
        tarotCardId: string;
        activeIndex: number;
        from: ProductItemSource;
      }),
    );
  };

  return (
    <div className="flex h-full min-h-svh flex-col items-center justify-start bg-[#fce8ce]">
      <div className="flex h-full w-full max-w-screen-md flex-col items-center overflow-hidden">
        <PageHeader
          statusBarColor="#fce8ce"
          titleColor="#31261D"
          title={selectedCardTitleByIndex[activeIndex]}
          IconLeft={() => (
            <div>
              <div
                onClick={() => {
                  navigate("/home");
                  dispatch(removeExpSessionId());
                }}
                className="flex h-9 w-9 cursor-pointer items-center justify-center"
              >
                <CloseIcon color={"#31261D"} />
              </div>
            </div>
          )}
          transparent
          backColor="#fff"
        />
        <div className="relative min-h-[265px]">
          <Swiper
            onActiveIndexChange={(e) => setActiveIndex(e.activeIndex)}
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={16}
            onSwiper={setSwiper}
          >
            {cards.map((card: Card, index: number) => (
              <SwiperSlide>
                {({ isActive }) => {
                  const swiper = useSwiper();
                  const handleClick = isActive
                    ? undefined
                    : () => {
                        swiper?.slideTo?.(index);
                      };
                  return (
                    <TarotCardWrapper onClick={handleClick}>
                      <ImageWithModal
                        description={tarotMeanings?.[index]}
                        onClick={handleClick}
                        isUpright={card.is_upright}
                        src={s3ImageUrl(`${card.card_id}.webp`)}
                        className={`${isActive ? "opacity-100" : "opacity-50"} h-[420px] object-contain transition-all duration-300 ease-in-out md:h-[300px] md:w-[200px] ${card.is_upright ? "" : "rotate-180"}`}
                        alt=""
                      />
                    </TarotCardWrapper>
                  );
                }}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          ref={containerRef}
          className="mt-2 min-h-[140px] w-full overflow-scroll px-4 text-center scrollbar-hide sm:mt-4"
        >
          {tarotMeanings.map((item, index) => {
            const isFocus =
              index === activeIndex || (activeIndex === 2 && index === 3);
            return (
              <span
                key={index}
                ref={(el) => (spanRefs.current[index] = el)}
                className={`text-center font-inter text-14px font-semibold leading-5 text-brand-4 sm:text-16px md:text-18px ${isFocus ? "block text-14px" : "hidden opacity-50"}`}
              >
                {`${item} `}
              </span>
            );
          })}
        </div>
        <Gap size="M" />
        {/* Drink recommend */}
        <div
          {...useSwipeable({
            onSwipedLeft: () => {
              if (activeIndex < 2) {
                setActiveIndex(activeIndex + 1);
                outsideSlideTo(activeIndex + 1);
              }
            },
            onSwipedRight: () => {
              if (activeIndex > 0) {
                setActiveIndex(activeIndex - 1);
                outsideSlideTo(activeIndex - 1);
              }
            },
            trackTouch: true,
          })}
          onClick={() => {
            if (FLAGS.ENABLE_DEMO) return;
            onClickDrink();
          }}
          className="mt-10 flex w-full cursor-pointer flex-col items-center justify-center px-4 md:mt-8 md:py-2"
        >
          <div className="relative flex min-h-[152px] w-full flex-row items-center justify-end rounded-2xl bg-[#CA412E] px-4 py-2 sm:h-[148px] md:h-[180px] md:py-[70px]">
            {activeDrink ? (
              <div className="flex w-full flex-col">
                <span
                  className={`font-inter ${
                    isSpecialDrink
                      ? "text-11px font-bold text-purple-300"
                      : "text-11px font-semibold text-divide"
                  } md:text-14px`}
                >
                  {isSpecialDrink ? t("hiddenDrink") : t("recommendDrink")}
                </span>
                <span
                  className={`font-space text-16px font-semibold ${isSpecialDrink ? "text-brand-shape-3" : "text-brand-shape-3"} md:text-24px`}
                >
                  {`${activeDrink?.name?.[language]}`}
                </span>
                <span className="font-inter text-12px font-semibold text-brand-shape-3 md:text-[18px]">
                  {`${formatCurrency(isLogin ? activeDrink?.pricing?.[0].member_value : activeDrink?.pricing?.[0].value)} `}
                  {isLogin ? (
                    <span className="ml-2 font-inter text-12px font-semibold text-brand-shape-3 line-through md:text-[18px]">
                      {`(${formatCurrency(activeDrink?.pricing?.[0].value)})`}
                    </span>
                  ) : null}
                </span>
                <span className="font-inter text-11px font-normal text-divide md:text-18px md:leading-[24px]">
                  {activeDrink?.description?.[language]}
                </span>
                <span className="font-inter text-12px font-semibold text-divide md:text-18px md:leading-[24px]">
                  {drinkMeanings[activeIndex]?.replace(
                    "{drink_name}",
                    activeDrink?.name?.[language],
                  )}
                </span>
              </div>
            ) : (
              <div className="flex h-full w-full flex-col justify-start">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-col">
                    <Skeleton.Button active style={{ height: 12, width: 24 }} />
                    <Skeleton.Input active style={{ height: 12, width: 36 }} />
                  </div>
                  <div className="absolute -top-[40px] right-10">
                    <Skeleton.Node
                      active
                      style={{
                        height: 100,
                        width: 64,
                        marginLeft: 16,
                        marginTop: 8,
                        borderRadius: 4,
                      }}
                    />
                  </div>
                </div>
                <Skeleton.Button active style={{ height: 12, width: 50 }} />
                <Skeleton.Button
                  active
                  style={{ height: 48, width: "100%" }}
                  size="small"
                />
              </div>
            )}
            <img
              src={activeDrink?.image}
              alt=""
              className="absolute -top-[40px] right-4 h-auto w-28 object-bottom sm:-top-[60px] md:w-48"
            />
            <div className="absolute right-[20px] top-[36px] flex h-8 w-8 flex-row items-center justify-center rounded-full bg-brand-3 sm:-top-[60px]">
              <IconPlus color="#8D3D3A" />
            </div>
            <div
              onClick={(e) => {
                onClickDrink();
              }}
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
              }}
              className="h-auto w-full"
            >
              {isSpecialDrink && (
                <Lottie
                  autoplay
                  width={"100%"}
                  height={100}
                  loop
                  animationData={require("assets/confetti_lottie.json")}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-row items-center justify-center gap-1 py-2">
          {[...Array(3)].map((_, index) => (
            <button
              key={index}
              style={{
                backgroundColor: activeIndex === index ? "#CA412E" : "#fff",
                width: activeIndex === index ? 24 : 6,
              }}
              onClick={() => {
                setActiveIndex(index);
                outsideSlideTo(index);
              }}
              className={`hover:bg-green-200 h-[6px] w-[6px] cursor-pointer rounded-full border-none bg-gray-200 p-0`}
            />
          ))}
        </div>
        <Gap size="M" />

        <Modal
          onClose={() => {
            setShowSpecialDrinkModal(false);
            setShowSpecialDrinkAnimation(true);
          }}
          open={showSpecialDrinkModal}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModalDialog
            sx={{
              p: 2,
              borderRadius: 12,
              background: "#fff",
            }}
            variant={"plain"}
          >
            <div className="flex h-full w-full flex-col items-start">
              <img
                src={activeDrink?.image}
                alt=""
                className="absolute -top-[72px] right-2 h-auto w-28 object-bottom md:w-28"
              />
              <div
                onClick={() => {
                  setShowSpecialDrinkModal(false);
                  setShowSpecialDrinkAnimation(true);
                }}
                className="absolute -left-2 -top-2 z-50 cursor-pointer rounded-full bg-white p-2"
              >
                <CloseIcon color={"#31261D"} width="12px" height="12px" />
              </div>
              <Lottie
                autoplay
                width={"100%"}
                style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                height={100}
                loop
                animationData={require("assets/confetti_lottie.json")}
              />
              <span className="text-left font-space text-[20px] font-semibold text-brand">
                Chúc mừng bạn!
              </span>
              <span className="mt-1 text-left font-inter text-14px font-normal text-brand-4">
                {`Bạn đã khám phá ra`}
                <span className="ml-1 mt-2 text-left font-inter text-14px font-semibold text-brand">
                  {`${activeDrink?.name?.[language]}`}
                </span>
                {` - một món đồ uống bí ẩn của Sắn.`}
              </span>
            </div>
          </ModalDialog>
        </Modal>
      </div>
      <div className="z-[9999] h-full">
        <ProductDetailBottomSheet />
      </div>
    </div>
  );
}
