import { BillItem, ProductItem } from "../../store/type";

import dayjs from "dayjs";
import { formatCurrency } from "../../utils/ConcurencyUtils";
import { generateCustomizeDescription } from "utils/OrderUtils";
import { useAppSelector } from "store/hook";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "../../hooks/useLanguage";

export type OrderInfoProps = {
  items?: BillItem[];
};

export function OrderInfo(props: OrderInfoProps) {
  const { t } = useLanguage();
  const { items = [] } = props;
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const { isAuthenticated: isLogin } = useAuthContext();

  return (
    <div className={`rounded-lg bg-white py-2`}>
      {items?.map((item, index) => {
        const fullInfoItem = allItems?.[item.item_id];
        const isOneSize = fullInfoItem?.pricing?.length === 1;
        const description = generateCustomizeDescription(item, isOneSize, t);

        const toppings = item.children
          .map((item) => item.item_name)
          .join("\n• ");

        const toppingsPrice = item.children.reduce((acc, item) => {
          const { item_id } = item;
          const fullInfoItem = allItems?.[item_id];
          const selectedSize = fullInfoItem?.pricing?.find?.(
            (item) => item.size === "small",
          );
          const price =
            (isLogin ? selectedSize?.member_value : selectedSize?.value) || 0;
          return acc + price;
        }, 0);

        return (
          <div
            key={`${index}-${item.item_id}-store-info`}
            className={`flex flex-col px-4`}
          >
            <div className={`flex w-full flex-col justify-between py-2`}>
              <div className={`flex w-full flex-row justify-between`}>
                <p className={`text-14px font-normal text-brand-4`}>
                  {item.item_name}
                </p>
                <p className={`text-14px font-bold text-brand-4`}>
                  {formatCurrency(item.price + toppingsPrice)}
                </p>
              </div>
              <p className={`text-14px font-normal text-tertiary`}>
                {description}
              </p>
              {item.children.length > 0 && (
                <p
                  className={`whitespace-pre-line text-14px font-normal text-tertiary`}
                >
                  {`• ${toppings}`}
                </p>
              )}
              <div className={`mt-1 flex w-full flex-row justify-between`}>
                <p className={`text-12px font-normal text-tertiary`}>
                  {t("quantity")}
                </p>
                <p
                  className={`text-14px font-normal text-tertiary`}
                >{`x${item.quantity}`}</p>
              </div>
              {/* <div className={`mt-1 flex w-full flex-row justify-between`}>
                <p className={`text-12px font-normal text-tertiary`}>
                  {t("price")}
                </p>
                <p className={`text-14px font-normal text-tertiary`}>
                  {formatCurrency(item.price * item.quantity)}
                </p>
              </div> */}
            </div>
            {index < items?.length - 1 && (
              <div className={`h-[1px] w-full bg-divide`} />
            )}
          </div>
        );
      })}
    </div>
  );
}
