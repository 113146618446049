import { Gap } from "./gap/Gap";
import { s3ImageUrl } from "utils/FileUtils";
export const TarotCardWrapper = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div className="relative" onClick={onClick}>
      <img
        src={s3ImageUrl("tarot_border.webp")}
        alt="tarot_border"
        className="absolute left-0 top-0 z-10 h-full w-full"
      />
      <div
        onClick={onClick}
        className="px-4 pb-[42px] pt-4 md:pb-[92px] md:pt-7"
      >
        {children}
      </div>
    </div>
  );
};
