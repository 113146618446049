import { WithSnackbarProps, withSnackbar } from "hoc/withSnackbar";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { mockTarotResult } from "mocks/mockTarotResult";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";

export type TarotQuestionProps = {};

function TarotQuestion(props: TarotQuestionProps & WithSnackbarProps) {
  const { showSnackbar = () => null } = props;
  const { t } = useLanguage();
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [isValidContent, setIsValidContent] = useState(false);

  const MIN_CONTENT_LENGTH = 0;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { topicType = "general" } = state || {};
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const mapTopicType = (topicType: string) => {
    switch (topicType) {
      case "general":
        return t("general");
      case "love":
        return t("love");
      case "career":
        return t("career");
      case "health":
        return t("health");
      default:
        return t("general");
    }
  };

  const mapPlaceholderByTopicType = (topicType: string) => {
    switch (topicType) {
      case "general":
        return {
          q1: "Ví dụ: Tôi đang cảm thấy rất phấn khởi khi chuẩn bị đi du học.",
          q2: "Ví dụ: Tổng quan 3 tháng tới của tôi sẽ như thế nào?",
        };
      case "love":
        return {
          q1: "Ví dụ: Em đang crush một anh khóa trên nhưng chưa biết nên làm thế nào.",
          q2: "Ví dụ: Liệu em có cơ hội nào không ạ?",
        };
      case "career":
        return {
          q1: "Ví dụ:  Tôi đang có cơ hội chuyển sang chi nhánh ở nước ngoài nhưng khá phân vân vì phải đi xa nhà.",
          q2: "Ví dụ: Đây có phải cơ hội phù hợp cho tôi không?",
        };
      case "finance":
        return {
          q1: "Ví dụ: Hiện tại thu nhập của tôi chỉ đủ cho sinh hoạt gia đình.",
          q2: "Ví dụ: Trong thời gian tới tôi có cơ hội có công việc làm thêm để tăng thu nhập không?",
        };
      default:
        return {
          q1: "Ví dụ: Hiện tại tôi đang suy nghĩ rất nhiều về cuộc sống và những quyết định sắp tới.",
          q2: "Ví dụ: Thời gian tới tôi có cơ hội để tăng thêm thu nhập không?",
        };
    }
  };

  return (
    <div
      style={{ height: "100vh" }}
      className="flex flex-col items-center justify-center bg-[#fce8ce] font-inter"
    >
      <div className="flex h-full w-full max-w-screen-md flex-col items-center">
        <PageHeader
          title={mapTopicType(topicType)}
          transparent
          backColor="#31261D"
          statusBarColor="#fce8ce"
        />
        {/* <Gap size="L" /> */}

        <div className="flex w-full max-w-screen-md px-4">
          <div className="flex w-full flex-col items-center justify-center">
            <Gap size="M" />
            <p className="px-4 text-center font-inter text-[16px] font-normal leading-[20px] text-brand-31 md:text-[18px] md:leading-[32px]">
              {"Xem nhanh tổng quan 3 tháng tới"}
            </p>
            <Gap size="S" />
            <SanButton
              title={"Chọn lá Tarot ngay"}
              loading={isLoading}
              transparentBackground
              textClassName="text-white"
              onClick={async () => {
                try {
                  setIsLoading(true);
                  setTimeout(() => {
                    setIsLoading(false);
                    navigate("/tarot-experience", {
                      state: {
                        topicType: topicType,
                        context: ``,
                      },
                    });
                  });
                } catch (error) {
                  setIsLoading(false);
                  showSnackbar({
                    message: t("somethingWentWrong"),
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="relative flex w-full max-w-screen-md justify-center py-6">
          <div className="absolute top-1/2 z-0 h-[1px] w-3/5 bg-brand-31" />
          <div className="z-10 flex w-5 flex-col items-center justify-center bg-[#fce8ce]">
            <p className="bg-[#fce8ce] px-2 text-center font-inter text-[14px] font-normal leading-[20px] text-brand-31 md:text-[18px] md:leading-[32px]">
              {"hoặc"}
            </p>
          </div>
        </div>

        <div className="w-full px-10">
          {/* <h1 className="mb-2 text-center font-inter text-[24px] font-semibold leading-[32px] text-brand-4 md:text-[48px] md:leading-[56px]">
            {t("tarotQuestion")}
          </h1> */}
          <p className="px-0 text-center font-inter text-[14px] font-normal leading-[20px] text-brand-31 md:text-[18px] md:leading-[32px]">
            {t("questionDescription")}
          </p>
        </div>
        <Gap size="S" />
        <div className="relative flex h-fit w-full flex-col items-center px-4">
          <span className="text-center text-16px font-normal leading-[20px] text-brand-4">
            Bạn hãy mô tả hoàn cảnh hiện tại của mình:
          </span>
          <textarea
            onChange={(e) => {
              setQ1(e.target.value);
              setIsValidContent(
                e.target.value.length > MIN_CONTENT_LENGTH &&
                  q2.length > MIN_CONTENT_LENGTH,
              );
            }}
            value={q1}
            style={{
              WebkitTextSizeAdjust: "160%",
            }}
            ref={textAreaRef}
            maxLength={250}
            className={`mt-2 h-24 max-h-[160px] w-full resize-none rounded-[16px] border-[2px] border-solid border-brand-31 bg-transparent px-2 py-2 text-center text-[10px] text-brand-31
               placeholder-gray-menu scrollbar-hide focus:border-brand focus:outline-none focus:ring-0 md:text-[18px]`}
            placeholder={mapPlaceholderByTopicType(topicType).q1}
          />
          <span className="absolute bottom-1 right-6 text-center text-[10px] font-normal leading-[20px] text-brand-4">{`${q1.length}/250`}</span>
        </div>
        <Gap size="MS" />
        <div className="relative flex h-fit w-full flex-col items-center px-4">
          <span className="text-center text-16px font-normal leading-[20px] text-brand-4">
            Bạn đang muốn hỏi điều gì?
          </span>
          <textarea
            onChange={(e) => {
              setQ2(e.target.value);
              setIsValidContent(
                e.target.value.length > MIN_CONTENT_LENGTH &&
                  q1.length > MIN_CONTENT_LENGTH,
              );
            }}
            value={q2}
            style={{
              WebkitTextSizeAdjust: "160%",
            }}
            ref={textAreaRef}
            maxLength={250}
            className={`mt-2 h-24 max-h-[160px] w-full resize-none rounded-[16px] border-[2px] border-solid border-brand-31 bg-transparent px-2 py-2 text-center text-[10px] text-brand-31
               placeholder-gray-menu scrollbar-hide focus:border-brand focus:outline-none focus:ring-0 md:text-[18px]`}
            placeholder={mapPlaceholderByTopicType(topicType).q2}
          />
          <span className="absolute bottom-1 right-6 text-center text-[10px] font-normal leading-[20px] text-brand-4">{`${q2.length}/250`}</span>
        </div>
        <Gap size="S" />
        {/* <span className="text-center text-[12px] font-normal leading-[20px] text-brand-4">{`*Hãy viết tối thiểu 50 kí tự cho mỗi câu trả lời bạn nhé!`}</span> */}
        <Gap size="S" />
        <div className="flex w-full max-w-screen-md px-4">
          <SanButton
            className={`${!isValidContent ? "border-brand-31 bg-transparent opacity-30" : "bg-brand"} border-[1px] `}
            title={t("selectCard").replace("{numOfCards}", "")}
            loading={isLoading}
            transparentBackground
            textClassName={`${!isValidContent ? "text-brand-31" : "text-white"}`}
            onClick={async () => {
              if (!isValidContent) {
                showSnackbar({
                  message: "Hãy trả lời những câu hỏi để tiếp tục",
                });
                return;
              }
              try {
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                  navigate("/tarot-experience", {
                    state: {
                      topicType: topicType,
                      context: `${q1}|${q2}`,
                    },
                  });
                });
              } catch (error) {
                setIsLoading(false);
                showSnackbar({
                  message: t("somethingWentWrong"),
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(TarotQuestion);
