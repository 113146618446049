import { useLocation, useNavigate } from "react-router-dom";

import { Gap } from "../../components/gap/Gap";
import { OrderInfo } from "./OrderInfo";
import PageHeader from "../../components/PageHeader";
import { PaymentInfo } from "./PaymentInfo";
import { StoreInfo } from "./StoreInfo";
import dayjs from "dayjs";
import { useAppSelector } from "../../store/hook";
import { useLanguage } from "../../hooks/useLanguage";

interface BillDetailPageProps {}

export default function BillDetailPage(props: BillDetailPageProps) {
  const { t } = useLanguage();
  const { state } = useLocation();
  const { bill } = state || {};
  return (
    <div className={`flex h-full min-h-screen w-full flex-col items-center bg-default-1`}>
      <PageHeader title={t("billTitle")} />
      <div className="h-auto w-full max-w-screen-md p-4">
        <p className={`text-18px font-bold text-brand-4`}>{t("paymentBill")}</p>
        <div className={`flex flex-row gap-1`}>
          <p className={`text-14px font-normal text-gray-menu`}>
            {`${t("number")}: `}
          </p>
          <span className={`text-14px font-semibold text-brand-4`}>{`#${bill?.order?.id}`}</span>
        </div>
        <div className={`flex flex-row gap-1`}>
          <p className={`text-14px font-normal text-gray-menu`}>
            {`${t("date")}: `}
          </p>
          <span>{dayjs(bill?.order?.date).format("DD/MM/YYYY")}</span>
        </div>

        <Gap size={"M"} />
        <OrderInfo items={bill?.items} />
        <Gap size={"S"} />
        <PaymentInfo charges={bill?.charges} />
      </div>
    </div>
  );
}
