import React, { useState } from "react";
import TableSection from "../../components/order/TableSection";
import { Gap } from "../../components/gap/Gap";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import PaymentMethodSection from "components/order/PaymentMethodSection";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";
import { debounce } from "lodash";
import { addNote } from "store/orderSlice";
import { PAYMENT_METHOD } from "helpers/constants";
import { Helmet } from "react-helmet";

const OrdersPage: React.FC = () => {
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const totalPrice = cartItemsTotalPrice(currentOrder?.items || []);
  const { t } = useLanguage();
  const dispatch = useAppDispatch();

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.VNPAY);

  const onChangeNote = debounce((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(addNote(e.target.value));
  }, 1000);

  return (
    <div className="h-full w-screen max-w-screen-md bg-default-1 p-4">
      <TableSection />
      <Helmet>
        <meta name="theme-color" content={"#fff"} />
      </Helmet>

      {currentOrder?.items?.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <Gap size={"L"} />
          <img
            src={s3ImageUrl("san_empty.png")}
            alt=""
            className="mx-auto w-1/2 md:w-1/3"
          />
          <Gap size={"M"} />
          <p className="text-14px font-semibold text-brand-4">{t("noOrder")}</p>
          <Gap size={"XXS"} />
          <p className="text-center text-12px font-normal text-gray-menu">
            {t("emptyStateDescription")}
          </p>
        </div>
      ) : (
        <div>
          {/* <Gap size={"S"} />
          <TimeEstimationSection /> */}
          <Gap size={"S"} />
          <MyOrderSection items={currentOrder?.items} />
          <Gap size={"S"} />
          <TotalPriceSection total={totalPrice} subTotal={totalPrice} />
          <Gap size={"S"} />
          <PaymentMethodSection />
          <Gap size={"M"} />
          <div className="">
            <p className={`text-16px font-bold text-brand-4`}>{"Ghi chú"}</p>
            <Gap size={"XS"} />
            <textarea
              name=""
              id=""
              onChange={onChangeNote}
              placeholder="Nhập ghi chú"
              className="min-h-[100px] w-full rounded-lg p-2 placeholder:text-tertiary focus:border-[1px] focus:border-solid focus:border-brand-1 focus:outline-none"
            />
          </div>
          <Gap size={"XL"} />
        </div>
      )}
    </div>
  );
};

export default OrdersPage;
