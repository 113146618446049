import IconBell from "../../assets/icons/icon-bell";
import IconSearch from "../../assets/icons/icon-search";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";

type IconWrapperProps = {
  children: JSX.Element;
  onClick?: () => void;
};

const IconWrapper = (props: IconWrapperProps) => {
  return (
    <div
      className={`${props.onClick && "cursor-pointer"}
     flex h-10 w-10 flex-row items-center justify-center rounded-full bg-white pl-1 pt-1`}
      onClick={props?.onClick}
    >
      {props.children}
    </div>
  );
};

export default function HomeHeader() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <header className="flex w-full flex-row items-center justify-between px-4 pt-4">
      <div className="flex flex-row items-center gap-2">
        <span className={`font-space whitespace-pre-line text-16px font-semibold text-black`}>
          {"Chào bạn!"}
        </span>
      </div>
      <img src={s3ImageUrl("san-logo.png")} alt="" className="h-12 w-12 -ml-10" />
      <div className="flex items-center justify-center gap-2">
        <IconWrapper>
          <IconSearch
            onClick={() => {
              navigate("/menu", {
                state: { isSearch: true },
              });
            }}
            className="h-6 w-6 md:w-6"
          />
        </IconWrapper>
      </div>
    </header>
  );
}
