import { s3ImageUrl } from "utils/FileUtils";

export const TarotLoading = () => {
  return (
    <div className="absolute z-[100] bg-brand-bg-50 inset-0 bottom-0 left-0 right-0 flex h-full w-full items-center justify-center">
      <img
        src={s3ImageUrl("tarot_animation.gif")}
        className="w-2/3 max-w-[250px] object-cover"
      />
    </div>
  );
};
