import { useLanguage } from "hooks/useLanguage";
import { s3ImageUrl } from "utils/FileUtils";

export type UseTarotTopic = {};

export const useTarotTopics = () => {
  const { t } = useLanguage();

  const topics = {
    general: {
      id: 0,
      title: t("general"),
      image: s3ImageUrl("general_header.webp"),
      bgColor: "#9e2a2b",
      type: "general",
      scale: "scale-[0.8]",
      secondMarginTop: 0,
      firstMarginTop: 0,
    },
    love: {
      id: 1,
      title: t("love"),
      image: s3ImageUrl("love_header.webp"),
      bgColor: "#ce5fb9",
      type: "love",
      scale: "",
      secondMarginTop: 70,
      firstMarginTop: "-mt-[40px]",
    },
    career: {
      id: 2,
      title: t("career"),
      image: s3ImageUrl("career_header.webp"),
      bgColor: "#f9b50b",
      type: "career",
      scale: "scale-[2]",
      secondMarginTop: 80,
      firstMarginTop: "-mt-[10px] sm:mt-8",
    },
    finance: {
      id: 3,
      title: t("finance"),
      image: s3ImageUrl("finance_header.webp"),
      bgColor: "#397a2a",
      type: "finance",
      scale: "",
      secondMarginTop: 60,
      firstMarginTop: "-mt-[58px] sm:-mt-5",
    },
  };

  return { topics };
};
