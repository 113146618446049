import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import CloseIcon from "assets/icons/close";
import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { s3ImageUrl } from "utils/FileUtils";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export type TarotGuideProps = {};

export default function TarotGuide(props: TarotGuideProps) {
  const navigate = useNavigate();
  const steps = [
    {
      title: "Sắn Tarot",
      content: "Đón nhận những thông điệp từ vũ trụ và tìm kiếm câu trả lời qua những lá bài Tarot kết hợp cùng AI",
      image: "tarot_girl_new.webp",
    },
    {
      title: "Chọn chủ đề",
      content: "Chọn một chủ đề bạn quan tâm",
      note: "Lưu ý: Hãy đăng nhập để mở khoá tất cả các chủ đề",
      image: "step1.webp",
    },
    {
      title: "Đặt câu hỏi",
      content: "Nhập câu hỏi bạn đang quan tâm để nhận thông điệp",
      image: "step2.webp",
    },
    {
      title: "Chọn lá Tarot",
      content: `Chọn 3 lá Tarot tương ứng với “hiện tại”, “tương lai” và \n“lời khuyên” (gắn kết hiện tại với tương lai)`,
      image: "step3.webp",
    },
    {
      title: "Giải mã",
      content: `Sắn sẽ giải mã các lá Tarot kèm những gợi ý đồ uồng cho từng lá`,
      image: "step4.webp",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="flex h-full min-h-dvh flex-col items-center justify-between overflow-hidden bg-[#fce8ce]">
      <div className="flex w-full max-w-screen-md flex-col">
        <PageHeader
          title="Giới thiệu"
          titleColor="#31261D"
          backColor="#31261D"
          transparent
          statusBarColor="#fce8ce"
          IconLeft={() => (
            <div>
              <div
                onClick={() => {
                  navigate("/tarot");
                }}
                className="flex h-8 w-8 cursor-pointer items-center justify-center"
              >
                <CloseIcon width={"16px"} height={"16px"} color={"#31261D"} />
              </div>
            </div>
          )}
        />
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full flex-row items-center justify-center gap-4 pt-4">
            <Swiper
              effect={"cards"}
              onActiveIndexChange={(e) => setActiveIndex(e.activeIndex)}
              grabCursor={true}
              centeredSlides={true}
              watchSlidesProgress={true}
              slidesPerView={1}
              initialSlide={0}
            >
              {steps.map((step, index) => (
                <SwiperSlide>
                  {({ isActive }) => {
                    const swiper = useSwiper();
                    const handleClick = (e) => {
                      if (!isActive) {
                        swiper?.slideTo?.(index);
                      }
                    };
                    return (
                      <div
                        className="flex w-full flex-col items-center justify-center"
                        onClick={handleClick}
                        key={index}
                      >
                        <div
                          className={`flex h-[400px] max-w-[300px] rounded-3xl 
                            ${index === 0 ? "w-[280px]" : "w-[180px] overflow-hidden border-[4px] border-solid border-black "}
                           sm:w-[300px]`}
                        >
                          <img
                            src={s3ImageUrl(`${step.image}`)}
                            alt=""
                            className="h-full w-full rounded-xl object-contain"
                          />
                        </div>
                        <Gap size="MS" />
                        <Gap size="S" />
                        <div className="flex w-full flex-col items-center justify-center px-4">
                          <div className="flex w-full flex-col items-center justify-center gap-2">
                            <div className="text-24px font-bold text-brand-4">
                              {steps[index].title}
                            </div>
                            <div className="whitespace-pre-line text-14px text-brand-4">
                              {steps[index].content}
                            </div>
                          </div>
                          {steps[index].note && (
                            <div className="flex w-full flex-col items-center justify-center gap-2">
                              <div className="text-12px text-neutrals-grey">
                                {steps[index].note}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-1 py-2 pb-4">
        {[...Array(steps.length)].map((_, index) => (
          <button
            key={index}
            style={{
              backgroundColor: activeIndex === index ? "#9F2B2B" : "#fff",
              width: activeIndex === index ? 24 : 6,
            }}
            onClick={() => {
              setActiveIndex(index);
            }}
            className={`hover:bg-green-200 h-[6px] w-[6px] cursor-pointer rounded-full border-none bg-gray-200 p-0 
                `}
          />
        ))}
      </div>
    </div>
  );
}
