import React, { useState, useRef, useEffect } from "react";
import type { RadioChangeEvent } from "antd";
import { Radio, Space, ConfigProvider } from "antd";
import IconRadio from "assets/icons/radio-icon";

export interface HotIceSelectorProps {
  items?: {
    value: any;
    label: string;
    description?: string;
    disabled?: boolean;
  }[];
  value?: any;
  hot?: boolean;
  onChange?: ({ value }: { value: boolean }) => void;
}

export default function HotIceSelector(props: HotIceSelectorProps) {
  const ref = useRef<any>(null);
  const { items = [], value, onChange } = props || {};
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((ref.current?.offsetWidth - 24) / 2);
  }, [ref.current]);

  return (
    <div className="flex flex-row justify-between">
      {items?.map((item, index) => {
        return (
          <div
            key={`${index}-${item.value}`}
            className="flex w-full flex-row"
            onClick={() =>
              item?.disabled ? null : onChange?.({ value: item.value })
            }
          >
            <div className="flex w-full flex-row items-center justify-center gap-2">
              <IconRadio
                color={item.disabled ? "#D9D9D9" : "#9F2B2B"}
                $isActive={value === item.value}
              />
              <p
                className={`text-14px ${item.disabled ? "text-[#D9D9D9]" : "text-brand-4"}`}
              >
                {item.label}
              </p>
            </div>
            {index < items.length - 1 && (
              <div className="h-full w-[1px] bg-border-1" />
            )}
          </div>
        );
      })}
    </div>
  );
}
