import { WithSnackbarProps, withSnackbar } from "hoc/withSnackbar";
import { setExpSessionId, setSessionId } from "store/sessionSlice";
import { useAppDispatch, useAppSelector } from "store/hook";
import { useEffect, useState } from "react";

import ArrowLeft from "assets/icons/arrow-left";
import { FLAGS } from "helpers/constants";
import { Gap } from "components/gap/Gap";
import InformationIcon from "assets/icons/icon-info";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { TarotLoading } from "./components/TarotLoading";
import { loginAsGuest } from "utils/AuthUtils";
import { s3ImageUrl } from "utils/FileUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export type TarotWelcomeProps = {};

function TarotWelcome(props: TarotWelcomeProps & WithSnackbarProps) {
  const {} = useMenuData();
  const { isAuthenticated: isLogin } = useAuthContext();
  const navigate = useNavigate();

  const sessionId = useAppSelector((root) => root.session.session_id);
  const expSessionId = useAppSelector((root) => root.session.exp_session_id);
  const { t } = useLanguage();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const guestToken = localStorage.getItem("guestToken");
    if (!isLogin && !guestToken) {
      loginAsGuest();
    }
  }, [isLogin]);

  useEffect(() => {
    if (!sessionId) {
      dispatch(setSessionId(uuidv4()));
    }
  }, [sessionId]);

  useEffect(() => {
    dispatch(setExpSessionId(uuidv4()));
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-start overflow-hidden bg-[#210909]">
      <div className="flex h-[68%] w-full max-w-screen-md flex-col">
        {FLAGS.ENABLE_DEMO ? (
          <Gap size="L" />
        ) : (
          <PageHeader
            IconRight={() => (
              <div
                onClick={() => navigate("/tarot-guide")}
                className="flex h-7 w-7 items-center gap-1"
              >
                <InformationIcon />
              </div>
            )}
            transparent
            statusBarColor="#210909"
            backColor="#fff"
          />
        )}
        <div className="px-4 pr-20">
          <span className="mb-4 font-space text-[30px] font-bold leading-[28px] text-white md:text-[48px] md:leading-[56px]">
            {t("tarotWelcome")}
          </span>
          <Gap size="XS" />
          <div className="font-inter text-[11px] font-normal leading-[16px] text-tertiary md:text-[18px] md:leading-[28px]">
            {t("tarotDescription")}
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-screen-md flex-col items-center justify-center px-4 pb-6 pt-10">
        <div
          className="relative w-full"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            style={{
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="absolute -bottom-28 left-0 right-0 z-10 w-1/2 max-w-[400px] sm:-bottom-56 md:w-[400px] xl:-bottom-32 xl:w-[170px]"
            src={s3ImageUrl("tarot_girl_new.webp")}
            alt=""
          />
          <img
            style={{
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="absolute -bottom-20 left-0 right-0 w-4/6 max-w-[400px] md:-bottom-12 md:w-[400px] xl:-bottom-20 xl:w-[200px]"
            src={s3ImageUrl("san_logo_bg.png")}
            alt=""
          />
          <img
            className="absolute -right-36 bottom-[250px] z-30 h-20"
            src={s3ImageUrl("red_cloud.png")}
            alt=""
          />
          <img
            className="absolute bottom-60 right-6 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -left-36 bottom-12 z-30 h-20"
            src={s3ImageUrl("red_cloud.png")}
            alt=""
          />
          <img
            className="absolute bottom-32 left-6 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
        </div>
        <SanButton
          className="bg-white"
          type="outline"
          loading={isLoading}
          title={t("startTarot")}
          onClick={async () => {
            setIsLoading(true);
            setTimeout(() => {
              navigate("/tarot-select-topic");
              setIsLoading(false);
            }, 2500);
          }}
        />
        <Gap size="XS" />
        {!isLogin && (
          <SanButton
            className="border-[1px] border-solid border-white bg-transparent"
            title={t("login")}
            onClick={async () => {
              navigate("/login", {
                state: {
                  source: "/home",
                },
              });
            }}
          />
        )}
        <Gap size="S" />
        {!FLAGS.ENABLE_DEMO && (
          <a href="/home" className="flex flex-row items-center gap-1">
            <ArrowLeft color="#FCD09A" width="16px" height="16px" />
            <span className="text-center text-[14px] font-semibold text-brand-shape-3">
              {t("goBackHome")}
            </span>
          </a>
        )}
        <Gap size="M" />
      </div>
      {/* {isLoading && <TarotLoading />} */}
    </div>
  );
}

export default withSnackbar(TarotWelcome);
