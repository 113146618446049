import ChevronLeft from "../../assets/icons/chevron-left";
import HeartIcon from "../../assets/icons/heart-icon";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import { Gap } from "../../components/gap/Gap";
import { Helmet } from "react-helmet";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Skeleton } from "antd";
import { useCustomization } from "hooks/useCustomization";
import { s3ImageUrl } from "utils/FileUtils";
import {
  useCreateItemFavoriteMutation,
  useRemoveFavoriteItemMutation,
} from "store/api/consumerApi";
import { useAppSelector } from "store/hook";
import { withSnackbar, WithSnackbarProps } from "hoc/withSnackbar";
import { useLanguage } from "hooks/useLanguage";
import { useAuthContext } from "contexts/AuthContext";

export enum ProductItemSource {
  "ORDER" = "ORDER",
  "MENU" = "MENU",
  "NONE" = "NONE",
}

const SHOW_BACKGROUND_CATEGORIES = ["cfe", "mch", "smt", "tea", "mkt"];

export interface ProductDetailPageProps {}

const ProductDetailPage = (
  props: ProductDetailPageProps & WithSnackbarProps,
) => {
  const { showSnackbar = () => null } = props;
  const { t } = useLanguage();
  const navigate = useNavigate();
  const {
    key,
    isFavorite,
    isLoading,
    product,
    setIsFavorite,
    CustomizationView,
    UpdateQuantityView,
  } = useCustomization({
    onConfirmClicked: () => {
      navigate(-1);
    },
  });

  const location = useLocation();
  const { pathname } = location;

  const { isAuthenticated: isLogin } = useAuthContext();

  const [addItemToFavorite, { data: addItemToFavoriteData }] =
    useCreateItemFavoriteMutation();
  const [removeFavoriteItem, { data: removeFavoriteItemData }] =
    useRemoveFavoriteItemMutation();

  const { image, id } = product || {};
  const itemCategory = product?.id?.slice(0, 3);

  return (
    <div className="relative flex h-screen w-full flex-col items-center">
      <ScrollToTop />
      <Helmet>
        <meta name="theme-color" content={"#fff"} />
      </Helmet>
      <div className="max-w-screen-md">
        <div className={`relative w-full max-w-screen-md overflow-hidden`}>
          <div className="absolute left-0 right-0 z-10 flex w-full flex-row justify-between px-4 py-3">
            <button
              className={`flex h-9 w-9 flex-col items-center justify-center rounded-full bg-white`}
              onClick={() => {
                if (key === "default") navigate("/home");
                else navigate(-1);
              }}
              aria-label="close"
            >
              <ChevronLeft />
            </button>
            <button
              className={`flex h-9 w-9 flex-col items-center justify-center rounded-full
             ${isFavorite ? "bg-secondary-7" : "bg-white"}`}
              onClick={async () => {
                if (!isLogin) {
                  navigate("/login", {
                    state: {
                      source: pathname,
                    },
                  });
                  return;
                }
                if (isFavorite) {
                  await removeFavoriteItem({ item_id: id });
                } else {
                  await addItemToFavorite({
                    item_id: id,
                  });
                }
                setIsFavorite(!isFavorite);
              }}
              aria-label="close"
            >
              <HeartIcon color={isFavorite ? "#fff" : "#858585"} />
            </button>
          </div>

          {isLoading ? (
            <Skeleton.Node active style={{ height: 354, width: "100vw" }} />
          ) : (
            <div className={`relative h-[354px] sm:h-[450px]`}>
              <img
                className="absolute h-[354px] w-screen scale-75 object-scale-down sm:h-[450px]"
                src={image}
                alt={`${product.name}-img`}
              />
              {SHOW_BACKGROUND_CATEGORIES.includes(itemCategory) ? (
                <img
                  src={s3ImageUrl(`${itemCategory}-bg.webp`, "items")}
                  className={`h-[354px] w-screen object-cover sm:h-[450px]`}
                  alt=""
                />
              ) : (
                <div className="h-[354px] w-screen sm:h-[450px]" />
              )}
            </div>
          )}
        </div>
        {CustomizationView()}
        <Gap size={"L"} />
        {UpdateQuantityView()}
      </div>
    </div>
  );
};

export default withSnackbar(ProductDetailPage);
