import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { motion } from "framer-motion";

import "swiper/css";
import "swiper/css/effect-cards";

import TarotCard from "./TarotCard";

export type TarotCarouselProps = {
  onCardClick: (e: any) => void;
  selectedCards?: number[];
};

const SCREEN_WIDTH = window.innerWidth;

export default function TarotCarousel(props: TarotCarouselProps) {
  const { onCardClick = (e) => null, selectedCards = [] } = props;
  const [selectedCardIndexes, setSelectedCardIndexes] = useState<number[]>([
    -1, -1, -1,
  ]);
  const emptySlotIndex = selectedCards.findIndex((slot) => slot === 0);

  const onCardClickHandler = (e: any, index: number) => {
    onCardClick?.(e);
    setSelectedCardIndexes((prev) => {
      const updated = [...prev];
      updated[emptySlotIndex] = index;
      return updated;
    });
  };

  useEffect(() => {
    selectedCards?.map((card, index) => {
      if (card === 0) {
        setSelectedCardIndexes((prev) => {
          const updated = [...prev];
          updated[index] = -1;
          return updated;
        });
      }
    });
  }, [selectedCards]);

  return (
    <div className="w-full">
      {/* Desktop */}
      <div className="hidden w-full sm:block">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          spaceBetween={8}
          centeredSlides={true}
          watchSlidesProgress={true}
          slidesPerView={7}
          initialSlide={8}
        >
          {Array.from({ length: 19 }).map((_, index) => (
            <SwiperSlide>
              {({ isActive }) => {
                const swiper = useSwiper();
                const handleClick = (e) => {
                  if (!isActive) {
                    swiper?.slideTo?.(index);
                  }
                };
                return (
                  <div>
                    {selectedCardIndexes?.includes(index) ? (
                      <div
                        className={`
                        mt-10 h-[120px] w-[72px] rounded-md border-[2px] border-dashed border-brand-1 sm:mt-16 sm:h-[170px] sm:w-[100px] 
                        `}
                      />
                    ) : (
                      <TarotCard
                        onCardClick={(e) => {
                          if (isActive) {
                            onCardClickHandler(e, index);
                          } else {
                            handleClick?.(e);
                          }
                        }}
                        isActive={isActive}
                        key={`tarot-card-${index}`}
                      />
                    )}
                  </div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* Mobile */}
      <div className="block w-full sm:hidden">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          spaceBetween={8}
          centeredSlides={true}
          watchSlidesProgress={true}
          slidesPerView={5}
          initialSlide={10}
        >
          {Array.from({ length: 19 }).map((_, index) => (
            <SwiperSlide>
              {({ isActive }) => {
                const swiper = useSwiper();
                const handleClick = isActive
                  ? undefined
                  : () => {
                      swiper?.slideTo?.(index);
                    };

                return (
                  <motion.div>
                    {selectedCardIndexes?.includes(index) ? (
                      <div
                        style={{
                          width: `${(SCREEN_WIDTH - 4 * 8) / 5}px`,
                          height: `${((SCREEN_WIDTH - 4 * 8) / 5) * 1.64}px`,
                          marginTop: `${(200 - ((SCREEN_WIDTH - 4 * 8) / 5) * 1.64) / 2}px`,
                        }}
                        className={`
                         rounded-md border-[2px] border-dashed border-brand-1 
                        `}
                      ></div>
                    ) : (
                      <TarotCard
                        onCardClick={(e) => {
                          if (isActive) {
                            onCardClickHandler(e, index);
                          } else {
                            handleClick?.();
                          }
                        }}
                        isActive={isActive}
                        key={`tarot-card-${index}`}
                      />
                    )}
                  </motion.div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
