import { WithSnackbarProps } from "hoc/withSnackbar";
import { usePasswordlessLogin } from "pages/LoginPage/usePasswordlessLogin";
import { useNavigate } from "react-router-dom";
import { setIsGuest, setLogin, setStInfo } from "store/authSlice";
import { useAppDispatch, useAppSelector } from "store/hook";
import { useLanguage } from "./useLanguage";
import axios from "axios";
import { clearLoginAttemptInfo } from "supertokens-web-js/recipe/passwordless";
import { clearGuestToken, setGuestToken } from "store/api/baseAxiosQuery";
import { isEmpty } from "lodash";
import { useAuthContext } from "contexts/AuthContext";

export type UseHandleLoginProps = {
  showSnackbar?: WithSnackbarProps["showSnackbar"];
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  usePhoneNumber?: boolean;
  source?: string;
};

export function useHandleLogin(props: UseHandleLoginProps) {
  const {
    showSnackbar = () => null,
    setIsLoading = () => null,
    usePhoneNumber,
    source = "",
  } = props;
  const { sendOTPViaPhoneNumber, sendOtpViaEmail, handleResendOtp } =
    usePasswordlessLogin();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const stInfo = useAppSelector((state) => state.auth.stInfo);
  const { setIsAuthenticated } = useAuthContext();

  const loginAsGuest = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_CONSUMER_BASE_URL}/v1/get_anonymous_jwt`,
        {
          jsonrpc: "2.0",
          id: "0",
          method: "get_anonymous_jwt",
          params: {},
        },
      );
      const { result: guestToken } = result.data;
      dispatch(setIsGuest(true));
      setGuestToken(guestToken);
    } catch (error) {
      console.log("~ login error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (emailOrPhone: string) => {
    try {
      const result = usePhoneNumber
        ? await sendOTPViaPhoneNumber(`+84${emailOrPhone}`)
        : await sendOtpViaEmail(emailOrPhone);
      setIsLoading(false);
      if (result.status === "OK") {
        dispatch(
          setStInfo({
            deviceId: result?.deviceId || "",
            preAuthSessionId: result?.preAuthSessionId || "",
          }),
        );
        navigate("/otp", {
          state: {
            source: props?.source,
          },
        });
      } else {
        showSnackbar({
          message: result.reason,
          type: "error",
        });
      }
    } catch (error) {
      if (`${error}`.includes("Phone number is invalid")) {
        showSnackbar({
          message: t("invalidPhoneNumber"),
          type: "error",
        });
      } else if (`${error}`.includes("Email is invalid")) {
        showSnackbar({
          message: t("invalidEmail"),
          type: "error",
        });
      } else
        showSnackbar({
          message: t("somethingWentWrong"),
        });
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async (code: string) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_CONSUMER_BASE_URL}/auth/signinup/code/consume`,
        {
          userInputCode: code,
          ...stInfo,
        },
      );
      const res = result.data;
      if (res.status === "OK") {
        const destination = isEmpty(source) ? "/home" : source;
        navigate(destination);
        dispatch(setLogin(true));
        dispatch(setIsGuest(false));
        setIsAuthenticated(true);
        clearGuestToken();
      } else if (res.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
        showSnackbar({
          message: t("invalidOtp"),
          type: "error",
        });
      } else if (res.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
        showSnackbar({
          message: t("expiredOtp"),
          type: "error",
        });
        navigate("-1");
      } else {
        await clearLoginAttemptInfo();
        showSnackbar({
          message: t("somethingWentWrong"),
        });
      }
    } catch (error) {
      console.log("~ login error:", error);
      showSnackbar({
        message: t("somethingWentWrong"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    try {
      const result = await handleResendOtp();
      if (result.status === "RESTART_FLOW_ERROR") {
        await clearLoginAttemptInfo();
        showSnackbar({
          message: t("loginFail"),
        });
      } else {
        showSnackbar({
          message: t("resendOtpSuccess"),
        });
      }
    } catch (error) {
      console.log("~ login error:", error);
      showSnackbar({
        message: t("somethingWentWrong"),
      });
    }
  };

  return {
    login,
    verifyOtp,
    resendOtp,
    loginAsGuest,
  };
}
