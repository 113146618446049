import { Button, Modal } from "antd";
import {
  removeItem as removeItemById,
  updateItemQuantity,
} from "../../store/orderSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useEffect, useState } from "react";

import IconMinus from "../../assets/icons/icon-minus";
import IconPlus from "../../assets/icons/icon-plus";
import { ProductItem } from "../../store/type";
import { ProductItemSource } from "pages/ProductDetailPage/ProductDetailPage";
import { formatCurrency } from "utils/ConcurencyUtils";
import { generateCustomizeDescription } from "utils/OrderUtils";
import { isEmpty } from "lodash";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";
import { useNavigate } from "react-router-dom";

export interface OrderItemProps {
  item: ProductItem;
  showDivide: boolean;
  showQuickButton?: boolean;
  onClick?: (product: ProductItem) => void;
}

export default function OrderItem(props: OrderItemProps) {
  const { item, showDivide, showQuickButton = true, onClick } = props || {};
  const { name, image, children, price, quantity, id, item_id } = item || {};
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const fullInfoItem = allItems?.[item_id];
  const isOneSize = fullInfoItem?.pricing?.length === 1;
  const { isAuthenticated: isLogin } = useAuthContext();
  const dispatch = useAppDispatch();
  const { t } = useLanguage();
  const {} = useMenuData();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const description = generateCustomizeDescription(item, isOneSize, t);

  const toppings = children.map((item) => item.name).join("\n• ");

  const toppingsPrice = children.reduce((acc, item) => {
    const { item_id } = item;
    const fullInfoItem = allItems?.[item_id];
    const selectedSize = fullInfoItem?.pricing?.find?.(
      (item) => item.size === "small",
    );
    const price =
      (isLogin ? selectedSize?.member_value : selectedSize?.value) || 0;
    return acc + price;
  }, 0);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      removeItem();
    }, 1000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const addItem = () =>
    dispatch(updateItemQuantity({ id: item.id, quantity: quantity + 1 }));

  const removeItem = () => {
    if (quantity === 1) dispatch(removeItemById(id));
    else dispatch(updateItemQuantity({ id, quantity: quantity - 1 }));
  };

  const onRemoveItem = () => {
    // if (quantity === 1) showModal();
    // else
    removeItem();
  };

  const showProductDetail = () => {
    navigate(`/product/${item_id}`, {
      state: { item, from: ProductItemSource.ORDER },
    });
  };

  return (
    <div>
      <div className={`mt-2 flex flex-row items-start justify-between`}>
        <div
          onClick={onClick ? () => onClick(item) : showProductDetail}
          className={`flex flex-row items-start`}
        >
          <img src={image} alt="" className={`mt-1 h-14 min-w-14 rounded-lg`} />
          <div className={`ml-2 flex min-h-14 flex-col items-start`}>
            <p className={`line-clamp-1 text-14px font-normal text-brand-4`}>
              {`${name}`}
            </p>
            <p className={`line-clamp-1 text-12px font-normal text-tertiary`}>
              {description}
            </p>
            {children?.length > 0 && (
              <p
                className={`whitespace-pre-line text-12px font-normal text-tertiary`}
              >
                {` • ${toppings}`}
              </p>
            )}
            <p className={`line-clamp-1 text-12px font-normal text-brand-31`}>
              {formatCurrency(price + toppingsPrice)}
            </p>
          </div>
        </div>
        {showQuickButton && (
          <div className="flex flex-col items-start justify-start">
            <div
              className={`flex flex-row items-center justify-center rounded-[40px] border border-border-1 px-2`}
            >
              <IconMinus color={"#9F2B2B"} onClick={onRemoveItem} />
              <input
                type="number"
                autoFocus={false}
                value={quantity}
                className={`w-[24px] text-center text-brand-4 focus:border-none focus:outline-none focus:ring-0`}
                onChange={(event) => {
                  dispatch(
                    updateItemQuantity({
                      id,
                      quantity: Number(event.target.value),
                    }),
                  );
                }}
              />
              <IconPlus color={"#9F2B2B"} onClick={addItem} />
            </div>
          </div>
        )}
      </div>
      {showDivide && <div className={`mt-2 h-[1px] w-full bg-[#FAFAFA]`} />}
      <Modal
        title="Xác nhận"
        open={open}
        style={{ marginTop: "50%", zIndex: 1000 }}
        onOk={handleOk}
        okText={"Xác nhận"}
        okButtonProps={{
          style: { backgroundColor: "#9F2B2B", fontSize: "12px" },
        }}
        cancelText={"Huỷ bỏ"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{`Bạn có chắc muốn xoá ${name}`}</p>
      </Modal>
    </div>
  );
}
